import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import BlogItem from "./BlogItem";
import useFireStore from "../../api/useFireStore";
import {IBlogEntry} from "./IBlogEntry";

function BlogList({limit}: { limit?: number }) {
    const {getBlogs} = useFireStore();
    const [blogs, setBlogs] = useState<IBlogEntry[]>([])

    const loadBlogs = async () => {
        let bb = await getBlogs(limit)
        setBlogs(bb)

    };

    useEffect(() => {
        loadBlogs()
    }, []);


    return (

        <Grid container spacing={0} columns={3} sx={{
            maxWidth: "85.44rem",
            width: "100%",
            paddingLeft: {xs: "1rem", md: "4rem"},
            paddingRight: {xs: "1rem", md: "4rem"},
            marginTop: "3rem",

        }}>
            {blogs.map((b) => {
                return <BlogItem item={b}/>
            })}

        </Grid>
    );
}

export default BlogList;
