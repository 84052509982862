import React from 'react';

function OpenAccordionImage() {
    return (
        <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.501953" width="28" height="28" rx="14" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15 7.50195H13V13.502H7V15.502H13V21.502H15V15.502H21V13.502H15V7.50195Z" fill="#676AA7"/>
        </svg>

    );
}

export default OpenAccordionImage;
