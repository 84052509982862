import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StellarMan from './StellarMan';

function HeaderStellarMan() {
	const [rightEyeStyles, setRightEyeStyles] = useState({});
	const [leftEyeStyles, setLeftEyeStyles] = useState({});
	const [stellarPositionStyles, setStellarPositionStyles] = useState({});
	const [isPercentageInView, setIsPercentageInView] = useState(false);
	const [stellarLockPosition, setStellarLockPosition] = useState<number | null>(null);

	// Make eye rotate base on where the cursor is.
	function handleRightEyeMovement(posX: number, posY: number) {
		const rightEye = document.getElementById('right-eye');
		if (!rightEye) return;
		const rightEyeDimensions = rightEye.getBoundingClientRect();
		const rightX = rightEyeDimensions.left + rightEyeDimensions.width / 2;
		const rightY = rightEyeDimensions.top + rightEyeDimensions.height / 2;
		const rightRad = Math.atan2(posX - rightX, posY - rightY);
		const rightRot = rightRad * (180 / Math.PI) * -1 + 180;
		setRightEyeStyles({
			transform: 'rotate(' + rightRot + 'deg)',
		});
	}

	// Make eye rotate base on where the cursor is
	function handleLeftEyeMovement(posX: number, posY: number) {
		const leftEye = document.getElementById('left-eye');
		if (!leftEye) return;
		const leftEyeDimensions = leftEye.getBoundingClientRect();
		const leftX = leftEyeDimensions.left + leftEyeDimensions.width / 2;
		const leftY = leftEyeDimensions.top + leftEyeDimensions.height / 2;
		const leftRad = Math.atan2(posX - leftX, posY - leftY);
		const leftRot = leftRad * (180 / Math.PI) * -1 + 180;
		setLeftEyeStyles({
			transform: 'rotate(' + leftRot + 'deg)',
		});
	}

	// Make Stellar move base on where cursor is.
	// Only do so if third block is not in view
	const handleEyeMove = (event: any) => {
		if (!isPercentageInView) {
			handleRightEyeMovement(event.clientX, event.clientY);
			handleLeftEyeMovement(event.clientX, event.clientY);
		}
	};

	// If block with big percentage comes into view, focus stellar eyes on that element.
	function handePercentageCheck() {
		const thirdBlock = document.getElementById('percentage-box');
		const percentage = document.getElementById('percentage');
		if (thirdBlock && percentage) {
			const section = thirdBlock.getBoundingClientRect();
			// If section's bottom part is in view
			if (section.bottom <= window.innerHeight) {
				const percentageLocation = percentage.getBoundingClientRect();
				setIsPercentageInView(true);
				handleLeftEyeMovement(percentageLocation.left, percentageLocation.top);
				handleRightEyeMovement(percentageLocation.left, percentageLocation.top);
			} else {
				setIsPercentageInView(false);
			}
		}
	}

	// Make sure that Stellar stops on correct position and moves back up if page is scrolled up
	function handleStellarMovement() {
		const block = document.getElementById('stopping-box');
		if (block) {
			const section = block.getBoundingClientRect();
			// Get bottom of the section
			const sectionLoc = section.y + section.height;
			const offset = 300;
			// If stellar is from 300px from the bottom, lock it in place
			if (section.top < offset) {
				setStellarLockPosition(sectionLoc);
				setStellarPositionStyles({
					top: section.y - offset,
				});
			} else {
				setStellarPositionStyles({
					top: 0,
				});
			}
		}
	}

	// Functions that are called when user scrolls
	const handleScrollEvent = () => {
		handePercentageCheck();
		handleStellarMovement();
	};

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('mousemove', handleEyeMove);
		document.addEventListener('scroll', handleScrollEvent);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousemove', handleEyeMove);
			document.removeEventListener('scroll', handleScrollEvent);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPercentageInView, stellarLockPosition]);

	const eyeClassName = isPercentageInView ? 'large-pupil' : '';
	return (
		<Box
			sx={{
				display: { md: 'block', xs: 'none' },
				position: 'fixed',
				left: '50%',
				marginLeft: '6rem',
				top: 0,
				...stellarPositionStyles,
			}}
			id={'stellar-man'}
			className={'stellar-container'}
		>
			<Box className={['stellar-man-hover'].join(' ')}>
				<StellarMan
					rightEyeStyles={rightEyeStyles}
					leftEyeStyles={leftEyeStyles}
					rightEyeClassName={eyeClassName}
					leftEyeClassName={eyeClassName}
					leftHandClassName={'stellar-left-hand-wave'}
					rightHandClassName={'stellar-right-hand-wave'}
				/>
			</Box>
		</Box>
	);
}

export default HeaderStellarMan;
