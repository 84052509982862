import React from 'react';
import {Box, Button, Link, Stack} from "@mui/material";
import YourBusiness from "../sections/YourBusiness";
import TeamSection from "../sections/TeamSection";
import ProductLeads from "../sections/ProductLeads";
import TeamTestimonials from "../sections/TeamTestimonials";
import ArrowImage from "../../assets/ArrowImage";
import Layout from "../Layout";

function Team() {

    return (

        <Layout>
            <>
                {/*<Hero/>*/}
{/*                <Mission/>*/}
                <Box sx={{height: {xs: "3rem", md: "6rem"}}}/>
                <ProductLeads/>
                <Box sx={{height: {xs: "3rem", md: "6rem"}}}/>
                <YourBusiness/>
                <Box sx={{height: {xs: "3rem", md: "6rem"}}}/>
                <TeamSection/>
                <Box sx={{height: {xs: "3rem", md: "6rem"}}}/>
                <TeamTestimonials/>
                <Box sx={{height: {xs: "3rem", md: "6rem"}}}/>
                <Stack sx={{width: "100%", display: "flex", alignItems: "center"}}>
                    <Link target={"_blank"} href={"https://calendly.com/aive-uus-kothinker/30min"}>
                        <Button sx={{marginTop: "1.5rem"}} variant={"contained"}>
                            Calendly meeting<ArrowImage
                            width={1.25}/>
                        </Button>
                    </Link>
                </Stack>
                <Box sx={{height: {xs: "3rem", md: "6rem"}}}/>

            </>
        </Layout>
    );
}

export default Team;
