import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Box, Grid, Link, Typography} from '@mui/material';
import Layout from '../Layout';
import AgendaItems from '../AgendaItems';
import {ReactComponent as Stellar1} from '../../assets/virtualShares-stellar-1.svg';
import {ReactComponent as Stellar2} from '../../assets/virtualShares-stellar-2.svg';
import {ReactComponent as Stellar3} from '../../assets/virtualShares-stellar-3.svg';
import ArrowImage from '../../assets/ArrowImage';
import {referralAtom} from "../../store/referralStore";
import {useAtomValue} from 'jotai';

const styles = {
    purple: {
        color: '#8338ed',
        fontFamily: 'Baloo',
        fontWeight: 400,
        fontSize: '1.75rem',
    },
    bodyDark: {
        color: '#000',
        fontFamily: "'Baloo 2'",
        fontSize: '1rem',
        lineHeight: '1.75rem',
        position: 'relative',
    },
    bodyLight: {
        color: '#6b6c89',
        fontFamily: "'Baloo 2'",
        fontSize: '1.125rem',
        lineHeight: '1.875rem',
    },
    koGrid: {
        display: 'flex',
        flexDirection: 'column',
        gap: {xs: '1rem', md: '2.25rem'},
        px: {xs: 'unset', md: '1.25rem'},
    },
};

function VirtualShares() {
    const referralCode = useAtomValue(referralAtom);

    return (
        <Layout>
            <Box sx={{maxWidth: '77.4rem', px: '1.125rem'}}>
                <Box sx={{display: {xs: 'none', md: 'block', position: 'absolute', right: `0`, top: `49rem`}}}>
                    <Stellar3/>
                </Box>
                <Grid
                    container
                    direction={{xs: 'column-reverse', md: 'row'}}
                    sx={{position: 'relative', alignItems: 'center'}}
                >
                    <Grid item xs={12} md={5}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                gap: {xs: '0.5rem', md: '1.5rem'},
                            }}
                        >
                            <Typography
                                variant={'h1'}
                                sx={{
                                    ...styles.purple,
                                    fontSize: {xs: '2.125rem', md: '3.125rem'},
                                    lineHeight: '100% !important',
                                    letterSpacing: '-0.0625rem',
                                    pt: {xs: '1rem', md: 'unset'},
                                }}
                            >
                                Become KoThinker’s virtual shareholder
                            </Typography>
                            <Typography sx={styles.bodyLight}>
                                We are big believers in community. That’s why we’re sharing{' '}
                                <span style={{whiteSpace: 'nowrap'}}>100 000</span> virtual shares to our community
                                members
                                that, on the 25th of October 2023, represent 5% of KoThinker.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            width: '100%',
                            top: '8rem',
                            height: 'fit-content',
                            pt: {xs: '1rem', md: 0},
                            marginLeft: 'auto',
                            minHeight: {xs: 'unset', md: '28rem'},
                        }}
                    >
                        <AgendaItems styles={{top: '-0.82rem', right: 0}}/>

                        <Box sx={{position: 'relative', right: {md: '5rem', xs: 0}}}>
                            <img src={'/images/solution-bg-5.png'} alt={'conference'} style={{width: '100%'}}/>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        px: {xs: 'unset', md: '4.5rem'},
                        position: 'relative',
                        alignItems: {xs: 'flex-start', md: 'center'},
                        justifyContent: 'center',
                        height: {xs: 'auto', md: '25rem'},
                        py: {xs: '3rem', md: 'unset'},
                        mt: {xs: 0, md: '5rem'},
                        mb: {xs: 0, md: '4.5rem'},
                    }}
                >
                    <ShareHolderImage image={'virtualShares-person-1.png'} left={-3} top={0}/>
                    <ShareHolderImage image={'virtualShares-person-2.png'} left={11.12} top={3.72}/>
                    <ShareHolderImage image={'virtualShares-person-3.png'} left={3.63} bottom={0}/>
                    <ShareHolderImage image={'virtualShares-person-4.png'} right={11.12} top={3.09}/>
                    <ShareHolderImage image={'virtualShares-person-5.png'} right={1.75} bottom={0}/>
                    <ShareHolderImage image={'virtualShares-person-6.png'} right={-3.06} top={0}/>
                    <Box sx={{display: {xs: 'none', md: 'block'}}}>
                        <Stellar1
                            style={{
                                position: 'absolute',
                                left: `20.25rem`,
                                top: `-5.22rem`,
                            }}
                        />
                    </Box>
                    <Box sx={{display: {xs: 'none', md: 'block'}}}>
                        <Stellar2
                            style={{
                                position: 'absolute',
                                right: `19.88rem`,
                                bottom: `-1.97rem`,
                                zIndex: 1,
                            }}
                        />
                    </Box>

                    <Typography
                        variant={'h2'}
                        sx={{
                            ...styles.purple,
                            fontSize: {xs: '2rem', md: '3rem'},
                            fontFamily: 'Baloo',
                            pb: {xs: '0.5rem', md: '1.5rem'},
                            zIndex: 1,
                        }}
                    >
                        How does it work?
                    </Typography>
                    <Typography sx={{...styles.bodyDark, textAlign: {xs: 'left', md: 'center'}}}>
                        All KoThinker community members who contribute to our growth receive virtual shares. <br/>
                    </Typography>
                    <Typography sx={{...styles.bodyDark, textAlign: {xs: 'left', md: 'center'}}}>
                        Virtual shares replicate real ownership in our company, allowing virtual shareholders to
                        participate in the company’s financial success.
                    </Typography>
                </Box>
                <Grid container sx={{pb: '2.25rem'}} gap={{xs: '2.5rem', md: 'unset'}}>
                    <Grid xs={12} md={4} sx={styles.koGrid}>
                        <Box
                            sx={{
                                backgroundImage: `url(/images/owl-book.png)`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                width: '75px',
                                height: '75px',
                            }}
                        />
                        <Typography sx={{...styles.purple, fontSize: '1.5rem !important'}}>
                            Being an active learner
                        </Typography>
                        <Typography sx={styles.bodyDark}>
                            Active participation during KoThinker sessions is not only valuable for your personal
                            growth, but it allows you to earn virtual shares. For example, you will earn 1 virtual share
                            for sharing the results of your learning experiment with others.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={styles.koGrid}>
                        <Box
                            sx={{
                                backgroundImage: `url(/images/social-share.png)`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                width: '75px',
                                height: '75px',
                            }}
                        />
                        <Typography
                            sx={{
                                ...styles.purple,
                                fontSize: '1.5rem !important',
                            }}
                        >
                            Spreading the good word
                        </Typography>
                        <Typography sx={styles.bodyDark}>
                            Sharing your experience on your social media platforms, mentioning us publicly, or writing a
                            testimonial are invaluable for us. For example, you will earn 20 virtual shares for writing
                            a
                            LinkedIn post about your KoThinker session.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={styles.koGrid}>
                        <Box
                            sx={{
                                backgroundImage: `url(/images/road.png)`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                width: '75px',
                                height: '75px',
                            }}
                        />
                        <Typography
                            sx={{
                                ...styles.purple,
                                fontSize: '1.5rem !important',
                            }}
                        >
                            Going the extra mile
                        </Typography>
                        <Typography sx={styles.bodyDark}>
                            If you’re enjoying your upskill journey and decide to invite your friends, participate in
                            feedback sessions or take the lead during KoThinker sessions, prepare to be rewarded! For
                            example, you will earn 15 virtual shares if your referral participates in their first
                            KoThinker session.
                        </Typography>
                    </Grid>
                </Grid>
                <Link
                    href={referralCode ? 'https://app.kothinker.com?ref='+referralCode : 'https://app.kothinker.com'}
                    target={'_blank'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: '1.5rem',
                        py: '0.62rem',
                        borderRadius: '2.5rem',
                        border: '1px solid #8338EC',
                        width: 'fit-content',
                        marginLeft: {xs: 'unset', md: 'auto'},
                        marginRight: 'auto',
                        color: '#8338EC !important',
                        fontWeight: 600,
                    }}
                >
                    Become our community member <ArrowImage width={1.2} color={'#8338ec'}/>
                </Link>
                <Grid
                    container
                    sx={{maxWidth: '55rem', marginLeft: 'auto', marginRight: 'auto', py: '4.5rem'}}
                    gap={{xs: '2rem', md: '4.5rem'}}
                    direction={{xs: 'column', md: 'row'}}
                >
                    <GridItem
                        title={'Participate in our financial success'}
                        subTitle={'When we earn, you earn.'}
                        isTextRight={true}
                        image={'/images/virtualShares-1.png'}
                    />
                    <GridItem
                        title={'Dividend-like payments'}
                        subTitle={
                            'You will receive a payment when our shareholders receive dividends.'
                        }
                        isTextRight={false}
                        image={'/images/virtualShares-2.png'}
                    />
                    <GridItem
                        title={'Voice your\n' + 'opinion'}
                        subTitle={'We value your thoughts and will improve based on these.'}
                        isTextRight={true}
                        image={'/images/virtualShares-3.png'}
                    />
                    <GridItem
                        title={'Gain information right'}
                        subTitle={'Regular updates on our business and achievements.'}
                        isTextRight={false}
                        image={'/images/virtualShares-4.png'}
                    />
                    <GridItem
                        title={'Company sale payment'}
                        subTitle={
                            'You will receive a payment if our shareholders sell our company.'
                        }
                        isTextRight={true}
                        image={'/images/virtualShares-5.png'}
                    />
                </Grid>
            </Box>
        </Layout>
    );
}

function ShareHolderImage({
                              image,
                              top,
                              bottom,
                              right,
                              left,
                          }: {
    image: string;
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
}) {
    return (
        <Box sx={{display: {xs: 'none', md: 'block'}}}>
            <img
                src={`images/${image}`}
                alt={'Virtual shareholder'}
                style={{
                    borderRadius: '100rem',
                    position: 'absolute',
                    left: `${left}rem`,
                    right: `${right}rem`,
                    top: `${top}rem`,
                    bottom: `${bottom}rem`,
                }}
            />
        </Box>
    );
}

function GridItem({
                      title,
                      subTitle,
                      image,
                      isTextRight,
                  }: {
    title: string;
    subTitle: string;
    image: string;
    isTextRight?: boolean;
}) {
    return (
        <Grid
            container
            direction={{xs: 'column-reverse', md: isTextRight ? 'row-reverse' : 'row'}}
            alignItems={'center'}
        >
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: {xs: 'left', md: 'auto'},
                }}
            >
                <Typography
                    sx={{
                        ...styles.purple,
                        fontSize: {xs: '1.5rem', md: '3rem'},
                        lineHeight: {xs: '100%', md: '3.125rem'},
                        pb: {xs: '0.5rem', md: '1.5rem'},
                        pt: {xs: '1.5rem', md: 'unset'},
                    }}
                >
                    {title}
                </Typography>
                <Typography>{subTitle}</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    width: '100%',
                    display: 'flex',
                    height: "200px",
                    justifyContent: isTextRight ? 'flex-start' : 'flex-end',
                    pr: {xs: 0, md: isTextRight ? '2.75rem' : 0},
                    pl: {xs: 0, md: isTextRight ? 0 : '2.75rem'},
                }}
            >
                <Box
                    sx={{
                        backgroundImage: `url(${image})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        width: '100%',
                        height: '100%',
                        borderRadius: "100px",

                    }}
                />

            </Grid>
        </Grid>
    );
}

export default VirtualShares;
