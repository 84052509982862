import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StellarMan from './StellarMan';
import { stellarStyles, StellarText } from '../SolutionStory';

function AngleStellarMan() {
	const [isVisible, setIsVisible] = useState(false);
	const [isTextVisible, setIsTextVisible] = useState(false);

	// If block with big percentage comes into view, focus stellar eyes on that element.
	function handleStellarFadeIn() {
		const blockElement = document.getElementById('section-7');
		if (blockElement) {
			const block = blockElement.getBoundingClientRect();
			// If section's bottom part is in view
			if (block.bottom - 150 <= window.innerHeight) {
				setTimeout(() => {
					setIsVisible(true);
				}, 2000);
				setTimeout(() => {
					setIsTextVisible(true);
				}, 3000);
			}
		}
	}

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('scroll', handleStellarFadeIn);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('scroll', handleStellarFadeIn);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			sx={{
				position: 'absolute',
				zIndex: 1,
				display: { xs: 'none', md: 'flex' },
				top: 0,
				right: '8rem',
			}}
			className={isVisible ? 'section-7-fade-in-bottom' : 'stellar-hidden'}
		>
			<Box sx={{ marginRight: '-1rem' }}>
				<Box
					sx={{
						zIndex: 1,
						display: { md: 'block', xs: 'none' },
						marginRight: '-1rem',
						position: 'relative',
						top: '-4rem',
						transform: 'scaleX(-1)',
					}}
					id={'stellar-man'}
					className={['stellar-container'].join(' ')}
				>
					<Box className={'angle-stellar-man'}>
						<StellarMan
							hideRightHand={true}
							hideLeftHand={true}
							rightEyeClassName={isVisible ? 'rotate-eye-section-7' : ''}
							leftEyeClassName={isVisible ? 'rotate-eye-section-7' : ''}
							isSmall={true}
						/>
					</Box>
				</Box>
			</Box>
			<Typography
				sx={{
					...stellarStyles.h2,
					fontSize: '3.125rem',
					lineHeight: '100%',
					fontWeight: 700,
					pt: '4rem',
				}}
				className={isTextVisible ? 'section-7-fade-in-bottom' : 'stellar-hidden'}
			>
				Interesting <br /> new <StellarText text={'angle'} fontSize={'3.125rem'} />
			</Typography>
		</Box>
	);
}

export default AngleStellarMan;
