import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import KoThinkerHero from "../sections/kothinker/KoThinkerHero";
import KoThinkerConnectTheDots from "../sections/kothinker/KoThinkerConnectTheDots";
import KoThinkerPricing from "../sections/kothinker/KoThinkerPricing";
import Layout from "../Layout";
import KotThinkerHow from "../sections/kothinker/KotThinkerHow";
import KothinkerStats from "../sections/kothinker/KothinkerStats";
import Testimonials from "../sections/Testimonials";
import GroupSelection from "../sections/kothinker/GroupSelection";
import KothinkerMission from "../sections/kothinker/KothinkerMission";
import UpcomingEvents from "../sections/kothinker/UpcomingEvents";
import {animateScroll as scroll} from 'react-scroll'; // Import animateScroll

function KoThinker() {

    useEffect(() => {
        console.log("hash", window.location.hash);

        if (window.location.hash === "#groups" || window.location.hash === "#events") {

            const timeoutId = setTimeout(() => {
                if (window.location.hash === "#groups") {
                    scrollToGroups();
                } else if (window.location.hash === "#events") {
                    scrollToEvents()
                }

                return () => clearTimeout(timeoutId);
            }, 1000);
        }
    }, []);

    const scrollToGroups = () => {
        const groupSelectionSection = document.getElementById("groupSelectionSection");
        if (groupSelectionSection) {
            scroll.scrollTo(groupSelectionSection.offsetTop - 120, {smooth: true, duration: 500});
        }
    };

    const scrollToEvents = () => {
        const groupSelectionSection = document.getElementById("eventSection");
        if (groupSelectionSection) {
            scroll.scrollTo(groupSelectionSection.offsetTop - 120, {smooth: true, duration: 500});
        }
    };


    return (
        <Layout>
            <>
                <KoThinkerHero/>
                <Box sx={{height: {xs: "3rem", md: "7rem"}}}/>
                <KothinkerMission/>
                <Box sx={{height: {xs: "3rem", md: "7rem"}}}/>
                <KothinkerStats/>
                <Box sx={{height: {xs: "3rem", md: "5rem"}}}/>
                <KotThinkerHow/>
                <Box sx={{height: {xs: "3rem", md: "5rem"}}}/>
                <KoThinkerPricing/>
                <Box sx={{height: {xs: "3rem", md: "5rem"}}}/>
                <UpcomingEvents/>
                <Box sx={{height: {xs: "3rem", md: "5rem"}}}/>
                <GroupSelection/>
                <Box sx={{height: {xs: "3rem", md: "5rem"}}}/>
                <Testimonials/>
                <Box sx={{height: {xs: "3rem", md: "7rem"}}}/>
                <KoThinkerConnectTheDots/>
                <Box sx={{height: {xs: "2rem", md: "5rem"}}}/>


            </>
        </Layout>

    )
        ;
}

export default KoThinker;
