import { Box } from '@mui/material';
import React from 'react';
import { ReactComponent as StellarImage } from '../../../assets/Steller.svg';
import { ReactComponent as StellarRightHand } from '../../../assets/Stellar-right-hand.svg';
import { ReactComponent as StellarLeftHand } from '../../../assets/stellar-left-hand.svg';
import { ReactComponent as StellarLeftHandSmall } from '../../../assets/stellar-left-hand-small.svg';
import { ReactComponent as StellarRightHandSmall } from '../../../assets/stellar-right-hand-small.svg';

function StellarMan({
	rightEyeClassName,
	leftEyeClassName,
	leftHandClassName,
	rightHandClassName,
	leftEyeStyles,
	rightEyeStyles,
	hideLeftHand,
	hideRightHand,
	isSmall,
}: {
	hideLeftHand?: boolean;
	hideRightHand?: boolean;
	rightEyeClassName?: string;
	leftEyeClassName?: string;
	leftHandClassName?: string;
	rightHandClassName?: string;
	leftEyeStyles?: React.CSSProperties;
	rightEyeStyles?: React.CSSProperties;
	isSmall?: boolean;
}) {
	return (
		<Box sx={{ position: 'relative', width: 'fit-content' }}>
			<div
				className={['stellar-right-eye', isSmall ? 'stellar-right-eye-small' : '', rightEyeClassName].join(' ')}
				id="right-eye"
				style={rightEyeStyles}
			/>
			<div
				className={['stellar-left-eye', isSmall ? 'stellar-left-eye-small' : '', leftEyeClassName].join(' ')}
				id="left-eye"
				style={leftEyeStyles}
			/>
			{!hideLeftHand && (
				<>
					<div
						className={[
							'stellar-left-hand',
							isSmall ? 'stellar-left-hand-small' : '',
							leftHandClassName,
						].join(' ')}
					>
						{isSmall ? (
							<StellarLeftHandSmall />
						) : (
							<StellarLeftHand style={{ width: ' 100%', height: '100%' }} />
						)}
					</div>
				</>
			)}
			{!hideRightHand && (
				<div
					className={[
						'stellar-right-hand',
						isSmall ? 'stellar-right-hand-small' : '',
						rightHandClassName,
					].join(' ')}
				>
					{isSmall ? (
						<StellarRightHandSmall />
					) : (
						<StellarRightHand style={{ width: ' 100%', height: '100%' }} />
					)}
				</div>
			)}
			<StellarImage
				style={{ zIndex: 2, width: isSmall ? '300px' : 'auto', height: isSmall ? '300px' : 'auto' }}
			/>
		</Box>
	);
}

export default StellarMan;
