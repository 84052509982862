import React, {useState} from 'react';
import {Button, Chip, IconButton, Stack, Typography} from "@mui/material";
import {IUpcomingSession} from "./model/IUpcomingSession";
import {format} from "date-fns";
import CloseIcon from "@mui/icons-material/Close";


export function convertBackendDateToLocalDate(dateString: string) {
    const dateFromString = new Date(dateString);
    return new Date(Date.UTC(dateFromString.getFullYear(), dateFromString.getMonth(), dateFromString.getDate(), dateFromString.getHours(), dateFromString.getMinutes(), dateFromString.getSeconds()))
}


function SessionSlide({sessionInfo}: { sessionInfo: IUpcomingSession }) {
    const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false)

    const startTime = convertBackendDateToLocalDate(sessionInfo.startTime)


    return (
        <Stack
            className="session-selection-box"
            sx={{
                position: "relative",
                borderRadius: {md: "20px", xs: "2.5rem"},
                padding: {md: 0, xs: "1rem"},
                height: {md: "100%", xs: "calc(100% - 4rem)"},
                width: "17rem",
                minHeight: "27rem",
            }}>

            <Stack sx={{height: "100%"}}>
                {!showMoreInfo ?
                    <Stack direction="column"
                           sx={{
                               alignItems: "start",
                               position: "relative",
                               minHeight: "23rem",
                               height: "100%",
                               padding: "24px",
                               paddingBottom: "0px",
                               justifyContent: 'space-between',
                           }}>
                        <Stack width="100%">
                            <Stack direction="row" sx={{
                                justifyContent: 'space-between',
                                width: '100%',
                            }}>

                                <Chip
                                    color={"success"}
                                    size={"small"}
                                    label="Free"
                                    sx={{
                                        margin: "0rem 0 1rem 0",
                                        fontWeight: 600,
                                        backgroundColor: "#05A97F",
                                        color: "#A3FFE7",
                                    }}
                                />
                                {!sessionInfo.endTime &&
                                    <Chip
                                        color={"success"}
                                        size={"small"}
                                        label="Scheduled"
                                        sx={{
                                            margin: "0rem 0 1rem 0",
                                            fontWeight: 600,
                                            backgroundColor: "#05A97F",
                                            color: "#A3FFE7",
                                        }}
                                    />
                                }


                            </Stack>
                            <Stack sx={{marginTop: "0.5rem"}}>
                                <Typography sx={{
                                    fontSize: "1rem",
                                    lineHeight: "1.125rem",
                                    color: "#FFFFFF",
                                    paddingRight: "1rem"
                                }}> {!sessionInfo.endTime ? "Next" : ""} {sessionInfo.title ? sessionInfo.title : "Global"} Session</Typography>

                                <Typography
                                    sx={{color: "#FFFFFF", fontSize: "1rem"}}>
                                    {!sessionInfo.endTime && startTime < new Date(new Date().getTime() + 10 * 60000) ? "Happening now" : format(startTime, "do  MMM yyyy 'at' HH:mm")}
                                </Typography>

                                <Typography
                                    sx={{
                                        fontSize: {xs: "1.5rem", md: "1.75rem"},
                                        color: "#FFFFFF",
                                        fontWeight: 700,
                                        marginTop: "1.5rem",
                                        lineHeight: {xs: "1.6rem", md: "2rem"}
                                    }}>{sessionInfo.description}
                                </Typography>

                            </Stack>
                        </Stack>
                        <Stack width="100%">


                            <Stack direction={"row"}
                                   sx={{display: "flex", alignItems: "center", mt: {xs: 3, lg: 0}}}>


                                {(!sessionInfo.endTime || sessionInfo.reviewLink) &&
                                    <Button
                                        variant={"contained"} fullWidth
                                        sx={{
                                            backgroundColor: '#FFFFFF',
                                            color: '#01B083',
                                            border: 'none',
                                            marginTop: '1rem',
                                            borderRadius: '6px',
                                            '&:hover': {
                                                backgroundColor: 'lightgray  !important',
                                            },
                                        }}
                                        onClick={() => {
                                            if (!sessionInfo.endTime) {
                                                window.open("https://app.kothinker.com/meeting/" + sessionInfo.id+"?signup=true", '_blank');
                                            } else if (sessionInfo.reviewLink) {
                                                window.open(sessionInfo.reviewLink, '_blank');
                                            }
                                        }
                                        }>
                                        {!sessionInfo.endTime ? "Let's go" : "Read review"}
                                    </Button>
                                }
                            </Stack>


                            <Typography
                                onClick={(e) => {
                                    if (sessionInfo.endTime) return;
                                    setShowMoreInfo(!showMoreInfo);
                                    e.stopPropagation();

                                    return false;
                                }}
                                align="center"
                                paddingTop="1.5rem"
                                sx={{
                                    zIndex: 100,
                                    padding: "1.5rem",
                                    cursor: !sessionInfo.endTime ? "pointer" : "default",
                                    fontSize: "1rem",
                                    fontWeight: 400,
                                    lineHeight: "1.125rem",
                                    color: "#FFFFFF",
                                }}
                            >
                                {!sessionInfo.endTime ? "More information" : "\u00A0"}
                            </Typography>

                        </Stack>
                    </Stack>
                    : <Stack
                        direction="column"
                        sx={{
                            alignItems: "start",
                            position: "relative",
                            height: "100%",
                            padding: "24px",
                            paddingTop: "14px",
                        }}
                    >
                        <Stack direction="row" justifyContent="end" width="100%">
                            <IconButton sx={{color: "#FFFFFF"}} edge="end" aria-label="Close" onClick={(e) => {
                                setShowMoreInfo(false);
                                e.stopPropagation();
                            }}>
                                <CloseIcon/>
                            </IconButton>
                        </Stack>
                        <Typography
                            onClick={(e) => {
                                setShowMoreInfo(false);
                                e.stopPropagation();
                            }}
                            align="left"
                            sx={{
                                fontSize: "1rem",
                                fontWeight: 400,
                                lineHeight: "1.125rem",
                                color: "white",
                                whiteSpace: "pre-line",
                            }}
                        >
                            {sessionInfo.preAgreement && sessionInfo.preAgreement != "" ? sessionInfo.preAgreement : "Currently no additional information available. Please check back later."}

                        </Typography>
                    </Stack>

                }
            </Stack>
        </Stack>
    )
        ;
}

export default SessionSlide;
