import React, { useRef } from 'react';
import { Stack } from '@mui/material';
import HeaderBar from '../app/HeaderBar';

function SolutionStoryLayout({ children }: { children: JSX.Element }) {
	const refs = useRef<any>({});

	return (
		<>
			<Stack
				sx={{
					alignItems: 'center',
					width: '100%',
					minHeight: 'calc(100vh - 12rem)',
					backgroundColor: '#0B001A',
				}}
				ref={(el) => (refs.current['home'] = el)}
			>
				<HeaderBar refs={refs} />
				<Stack sx={{ maxWidth: '89rem', width: '100%' }}>{children}</Stack>
			</Stack>
		</>
	);
}

export default SolutionStoryLayout;
