import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import CarouselDot, {CarouselDotType} from "../../carousel/CarouselDot";
import KoFounderSwiper from "../../carousel/KoFounderSwiper";


function KoThinkerConnectTheDots() {

    return (
        <>
            <Stack sx={{
                maxWidth: {xs: "calc(100% - 2rem)", md: "calc(100% - 8rem)"},
                width: {xs: "calc(100% - 2rem)", md: "calc(100% - 8rem)"},
                paddingLeft: {xs: "1rem", md: "4rem"},
                paddingRight: {xs: "1rem", md: "4rem"},
                position: "relative",
            }}>
                <Typography variant={"h1"} sx={{
                    textAlign: "center",
                    fontSize: {xs: "1.5rem", md: "3.75rem"},
                    lineHeight: {xs: "1.7rem", md: "3.75rem"},
                }}>Clients and Partners</Typography>

            </Stack>

            <Stack sx={{
                alignItems: "center",
                paddingTop: {md: "5rem", xs: "4rem"},
                width: "100%"
            }}>

                <KoFounderSwiper degrees={-5}>
                    <>
                        <CarouselDot title={"Partner"}
                                     background={"linear-gradient(90deg, #96D0E5 -0.06%, #F1CF78 99.94%)"}
                                     logo={"koos"}
                                     type={CarouselDotType.ELLIPSE}/>
                        <CarouselDot title={"Client"}
                                     background={"linear-gradient(90deg, #F1CF78 0.14%, #96D0E5 100.14%)"}
                                     logo={"khuul"}/>
                        <CarouselDot title={"Client"}
                                     background={"linear-gradient(90deg, #96D0E5 -0.06%, #F1CF78 99.94%)"}
                                     logo={"brainbase"}
                                     type={CarouselDotType.ELLIPSE}/>
                        <CarouselDot title={"Client"} logo={"edrinks"} background={"#F1CF78"}/>
                        <CarouselDot title={"Client"}
                                     background={"linear-gradient(270deg, #947EC7 -0.06%, #F1CF78 99.94%)"}
                                     logo={"enefit"}
                                     type={CarouselDotType.ELLIPSE}/>
                        <CarouselDot title={"Client"}
                                     logo={"sensusq"}
                                     background={"linear-gradient(90deg, #F1CF78 0.14%, #96D0E5 100.14%)"}/>
                        <CarouselDot title={"Client"}
                                     logo={"kideo"}
                                     background={"linear-gradient(90deg, #96D0E5 -0.06%, #F1CF78 99.94%)"}
                                     type={CarouselDotType.ELLIPSE}/>
                        <CarouselDot title={"Client"} logo={"fractory"}
                                     background={"#F1CF78"}/>
                    </>
                </KoFounderSwiper>

                <Box sx={{height: {md: "2rem", xs: "1rem"}}}/>
                <KoFounderSwiper reverse degrees={-5}>
                    <>
                        <CarouselDot title={"Client"}
                                     logo={"roofit"}
                                     background={"linear-gradient(90deg, #87CDBA 0.06%, #947EC7 100.06%)"}
                                     type={CarouselDotType.ELLIPSE}/>
                        <CarouselDot title={"Client"}
                                     logo={"fitq"}
                                     background={"linear-gradient(90deg, #947EC7 0.14%, #96D0E3 100.14%)"}/>
                        <CarouselDot title={"Partner"}
                                     logo={"koodj"}
                                     background={"linear-gradient(90deg, #96D0E5 -0.06%, #87CDBA 99.94%)"}
                                     type={CarouselDotType.ELLIPSE}/>
                        <CarouselDot title={"Client"}
                                     logo={"rikardia"}
                                     background={"#87CDBA"}/>
                        <CarouselDot title={"Partner"}
                                     logo={"edtech"}
                                     background={"linear-gradient(90deg, #87CDBA 0.06%, #947EC7 100.06%)"}
                                     type={CarouselDotType.ELLIPSE}/>
                        <CarouselDot title={"Partner"}
                                     logo={"ebs"}
                                     background={"linear-gradient(90deg, #947EC7 0.14%, #96D0E3 100.14%)"}/>
                        <CarouselDot title={"Client"}
                                     logo={"textmagic"}
                                     background={"linear-gradient(90deg, #96D0E5 -0.06%, #87CDBA 99.94%)"}
                                     type={CarouselDotType.ELLIPSE}/>
                        <CarouselDot title={"Client"} logo={"edrinks"} background={"#87CDBA"}/>
                    </>
                </KoFounderSwiper>
                <Box sx={{height: {md: "2rem", xs: "1rem"}}}/>

            </Stack>
        </>
    )
        ;
}

export default KoThinkerConnectTheDots;
