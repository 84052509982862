import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import MenuBar from "./MenuBar";
import MenuItems from "./MenuItems";
import KoThinkerImage from "../../assets/KoThinkerImage";

function Footer({refs}: { refs?: any }) {

    return (
        <Stack sx={{
            height: {md: "12rem", xs: "30rem"},
            position: "relative",
            width: "100%",
            alignItems: "center",
            overflow: "hidden"
        }}>
            <Stack sx={{
                backgroundColor: "#F6F6F9",
                width: {md: "118rem", xs: "100rem"},
                borderRadius: {md: "280rem / 70rem", xs: "120rem / 50rem"},
                height: {md: "30rem", xs: "45rem"},
                minHeight: {md: "30rem", xs: "45rem"},
                justifyContent: "start",
            }}>

            </Stack>
            <Stack sx={{
                display: {xs: "none", md: "block"},
                marginTop: "6rem",
                position: "absolute",
                width: {md: "calc(100% - 8rem)", xs: "calc(100% - 2rem)"},
                paddingLeft: {xs: "1rem", md: "4rem"},
                paddingRight: {xs: "1rem", md: "4rem"},
            }}>
                <MenuBar refs={refs} isFooter={true}/>
                <Stack direction={"row"} sx={{
                    display: {xs: "none", md: "flex"},
                    alignItems: "center",
                    justifyContent: "center",
                    width: "calc(100% - 8rem)",
                    paddingLeft: {xs: "1rem", md: "4rem"},
                    paddingRight: {xs: "1rem", md: "4rem"},
                }}>
                    <Grid container spacing={0} sx={{width: "25rem"}}>
                        <Grid item xs={4}>
                            <Typography sx={{color: "#947EC7", fontSize: "0.75rem"}}>KOTHINKER OÜ</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{color: "#947EC7", fontSize: "0.75rem"}}>REG NO 16837154</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{color: "#947EC7", fontSize: "0.75rem"}}>HELLO@KOTHINKER.COM</Typography>
                        </Grid>

                    </Grid>

                </Stack>

            </Stack>
            <Stack sx={{
                display: {xs: "flex", md: "none"},
                marginTop: "2.5rem",
                position: "absolute",
                width: "calc(100% - 2rem)",
                alignItems: "center",
            }}>
                <KoThinkerImage width={6.75}/>
                <Box sx={{height: "2rem"}}/>
                <MenuItems refs={refs} isFooter={true}/>

            </Stack>
        </Stack>
    )
        ;
}

export default Footer;
