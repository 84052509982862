import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import {IBlogEntry} from "./IBlogEntry";
import {Link} from "react-router-dom";
import {formatDate} from "../../util/formatUtil";


function BlogItem({
                      item,
                  }: { item: IBlogEntry }) {

    return (
        <Grid item md={1} xs={4} sx={{padding: "1.5rem 1.5rem 1.5rem 1.5rem"}} key={item.id}>
            <Link to={"/blog/" + item.id}>
                <Stack sx={{
                    borderRadius: "1.25rem",
                    border: "1px solid #E1E1E1",
                    overflow: "hidden",
                    height: "100%",
                    cursor: "pointer"
                }}>
                    <Stack sx={{
                        padding: "1.5rem",
                    }}>
                        <Typography sx={{color: "#7862C7", fontSize: "0.875rem"}}>
                            {formatDate(item.date)}
                        </Typography>
                        <Typography sx={{mt: "0.5rem", color: "#947EC7", fontSize: "1.25rem", fontWeight: "600", lineHeight:"1.465rem"}}>
                            {item.title}
                        </Typography>
                        <Typography sx={{mt: "1.5rem", mb: "0.5rem", color: "#434343", fontSize: "1rem", lineHeight:"1.35rem"}}>
                            {item.description}
                        </Typography>
                    </Stack>
                    <Stack sx={{height: "100%", justifyContent: "end"}}>
                        <Stack sx={{
                            minHeight: "10.3rem",
                            maxHeight: "10.3rem",
                            backgroundImage: `url("${item?.picUrl}")`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: item.picUrl ? "auto" : "none"
                        }}/>
                    </Stack>
                </Stack>
            </Link>
        </Grid>

    )
        ;
}

export default BlogItem;
