import React from 'react';

function LinkedInImage({size = 32}: { size?: number }) {
    return (<svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_269_3257)">
                <path
                    d="M29.4147 0.799805H2.99407C1.78344 0.799805 0.799805 1.7972 0.799805 3.02158V29.394C0.799805 30.6184 1.78344 31.6158 2.99407 31.6158H29.4147C30.6253 31.6158 31.6158 30.6184 31.6158 29.394V3.02158C31.6158 1.7972 30.6253 0.799805 29.4147 0.799805ZM10.1134 27.2135H5.54602V12.5071H10.1203V27.2135H10.1134ZM7.8297 10.4986C6.36457 10.4986 5.18145 9.3086 5.18145 7.85034C5.18145 6.39208 6.36457 5.20209 7.8297 5.20209C9.28796 5.20209 10.478 6.39208 10.478 7.85034C10.478 9.31548 9.29484 10.4986 7.8297 10.4986ZM27.2342 27.2135H22.6668V20.0598C22.6668 18.3539 22.6324 16.1597 20.2937 16.1597C17.9137 16.1597 17.5491 18.0169 17.5491 19.936V27.2135H12.9818V12.5071H17.3634V14.5157H17.4253C18.0375 13.3601 19.5302 12.1426 21.7519 12.1426C26.3743 12.1426 27.2342 15.1898 27.2342 19.1518V27.2135Z"
                    fill="#947EC7"/>
            </g>
            <defs>
                <clipPath id="clip0_269_3257">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>


    );
}

export default LinkedInImage;
