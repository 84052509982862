import React from 'react';
import {AppBar, useScrollTrigger} from '@mui/material';
import MenuBar from './MenuBar';
import {useAtomValue} from 'jotai';
import {themeAtom} from '../../store/themeStore';

function HeaderBar({ refs }: { refs?: any }) {
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 100,
	});

	const theme = useAtomValue(themeAtom);
	const isDarkTheme = theme.mode === 'dark';
	return (
		<>
			<AppBar
				elevation={0}
				sx={{
					paddingLeft: { xs: isDarkTheme ? '2rem' : '1rem', md: isDarkTheme ? '4rem' : '4rem' },
					paddingRight: { xs: isDarkTheme ? '2rem' : '1rem', md: isDarkTheme ? '4rem' : '4rem' },
					backgroundColor: trigger ? (isDarkTheme ? '#0B001A' : '#FFFFFF') : 'transparent',
					transition: 'background-color 600ms linear',
					paddingTop: '1.5rem',
					paddingBottom: '1rem',
				}}
			>
				<MenuBar refs={refs} />
			</AppBar>
		</>
	);
}

export default HeaderBar;
