import React from 'react';
import {Stack, Typography, Box} from "@mui/material";


export enum CarouselDotType {
    CIRCLE = 'CIRCLE', ELLIPSE = 'ELLIPSE'
}


function CarouselDot({
                         type = CarouselDotType.CIRCLE,
                         title,
                         background,
                         logo,
                     }: { type?: CarouselDotType, title: string, background: string, logo?: string }) {

    return (

        <Stack sx={{
            position: "relative",
            padding: "0.0rem",
            background: background,
            height: {md: "11.25rem", xs: "9.187rem"},
            width: type === CarouselDotType.CIRCLE ? {md: "11.25rem", xs: "9.187rem"} : {
                md: "25rem",
                xs: "16.08rem"
            },
            borderRadius: "13.75rem",
            alignItems: "center",
            justifyContent: "center",
            marginRight: {md: "0.3rem", xs: "0.5rem"},
        }}>
            <Stack sx={{width: type === CarouselDotType.CIRCLE ? "calc(100% - 4rem)" : "calc(100% - 8rem)"}}>
                <Box sx={{
                    mt: "1rem",
                    height: {md: "3.45rem", xs: "4.593rem"},
                    minHeight: {md: "3.45rem", xs: "4.593rem"},
                    maxHeight: {md: "3.45rem", xs: "4.593rem"},
                    width: "100%",
                    backgroundImage: "url(/images/" + logo + ".webp)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                }}
                ></Box>
                <Typography
                    sx={{
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: {md: "1.125rem", xs: "1rem"},
                        lineHeight: {md: "2.5rem", xs: "1.2rem"},
                        position: "relative",
                        marginTop: {md: "1rem", xs: "0.5rem"},
                    }}>
                    {title}
                </Typography>
            </Stack>
        </Stack>

    )
        ;
}

export default CarouselDot;
