import { Avatar, Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Icon } from '@iconify/react';

function AgendaItems({ styles }: { styles?: React.CSSProperties }) {
	return (
		<Box
			sx={{
				display: { xs: 'none', md: 'block' },
				position: 'absolute',
				zIndex: 1,
				...styles,
			}}
		>
			<AgendaItemCard
				active={false}
				title={'Check in'}
				finished={true}
				icon={'carbon:share-knowledge'}
				iconSize={25}
			/>
			<AgendaItemCard active={false} finished={true} title={'Roles of today'} icon={'eos-icons:role-binding'} />
			<AgendaItemCard
				active={true}
				title={'Last time experiments'}
				finished={false}
				icon={'mdi:human-greeting-proximity'}
				iconSize={25}
				description={'Who wishes to share their experiments from the last two weeks?'}
			/>
			<AgendaItemCard active={false} finished={false} title={'Stakeholder discussion'} icon={'octicon:goal-16'} />
			<AgendaItemCard active={false} finished={false} title={'Recap minute'} icon={'mdi:lightbulb-on-outline'} />
		</Box>
	);
}

function AgendaItemCard({
	active,
	finished,
	title,
	description,
	icon,
	iconSize = 20,
}: {
	active: boolean;
	finished: boolean;
	title: string;
	description?: string;
	icon: string;
	iconSize?: number;
}) {
	return (
		<Box
			sx={{
				backgroundColor: '#FFF',
				opacity: 0.925,
				minHeight: '50px',
				borderRadius: '20px',
				marginTop: '5px',
				display: 'flex',
				alignItems: 'center',
				padding: '12px',
				width: '20rem',
				filter: 'drop-shadow(0px 4px 40px rgba(125, 125, 125, 0.50))',
			}}
		>
			<Box sx={{ flexGrow: 1 }}>
				<Stack>
					<Grid container spacing={0} sx={{}}>
						<Grid item xs={2} alignSelf={'center'}>
							<CircularProgressWithContent
								active={active}
								finished={finished}
								content={<Icon icon={icon} fontSize={iconSize} />}
							/>
						</Grid>

						<Grid item xs={7} alignSelf={'center'} paddingLeft={1}>
							<Typography
								sx={{
									fontWeight: 700,
									fontSize: '1rem',
									color: '#000',
								}}
								component="span"
							>
								{title}
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Grid
								sx={{
									display: active ? 'block' : 'none',
								}}
								item
								xs
								container
								direction="column"
								rowSpacing={0}
								alignItems={'flex-end'}
							>
								<Grid item xs rowSpacing={0} textAlign="right">
									<Button
										sx={{
											'&:hover': {
												backgroundColor: '#00AD6F',
												color: '#FFF',
											},
											borderRadius: '0.375rem',
											fontSize: '0.8rem',
											maxHeight: '1.4rem !important',
											minHeight: '1.4rem !important',
											height: '1.4rem !important',
											fontWeight: 700,
											lineHeight: '0.875rem',
											padding: '0.3rem 0.3rem',
											backgroundColor: '#0CDBA6',
											color: '#FFF',
										}}
									>
										Next
									</Button>
								</Grid>
								<Grid
									item
									xs
									sx={{
										fontSize: '0.875rem',
										lineHeight: '1.25rem',
										display: 'flex',
										justifyContent: 'end',
										padding: '4.8px',
									}}
								>
									00:15
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Box sx={{ marginTop: '25px', display: description ? 'block' : 'none' }}>
						<Typography variant={'body2'} color={'text.primary'} sx={{ whiteSpace: 'pre-line' }}>
							{description}
						</Typography>
					</Box>
				</Stack>
			</Box>
		</Box>
	);
}

function CircularProgressWithContent({
	content,
	active,
	finished,
}: {
	content: any;
	active: boolean;
	finished: boolean;
}) {
	return (
		<Box position="relative" display="inline-flex" width={'50px'} sx={{ top: '-31px' }}>
			{active ? (
				<CircularProgress
					thickness={4}
					variant={'determinate'}
					value={100}
					size={52}
					sx={{
						color: '#e5e5e5',
						position: 'absolute',
					}}
				/>
			) : (
				<></>
			)}

			<CircularProgress
				thickness={4}
				size={52}
				variant="determinate"
				value={77}
				sx={{ position: 'absolute', color: active ? '#07E092' : '#ffffff' }}
			/>
			<Avatar
				sx={{
					position: 'absolute',
					top: '4px',
					left: '4px',
					width: 44,
					height: 44,
					backgroundColor: active ? '#9B51E0' : finished ? '#a8a8a8' : '#E0E0E0',
					color: '#ffffff',
				}}
			>
				{content}
			</Avatar>
		</Box>
	);
}

export default AgendaItems;
