import React, {useState} from 'react';
import {Box, Stack, Typography} from "@mui/material";
import ReactCardFlip from "react-card-flip";


const styles = {
    card: {
        alignItems: "center",
        width: "calc(100% - 4rem)",
        height: "15rem",
        borderRadius: "2rem",
        padding: "2rem",
        backgroundColor: "#F6F6F9",

    },
};

function TeamProfile({
                         name,
                         title,
                         desc,
                         picUrl,
                         flipPicUrl
                     }: { name: string, title: string, desc: string, picUrl: string, flipPicUrl: string }) {

    const [isFlipped, setIsFlipped] = useState(false)

    return (
        <Box onMouseEnter={() => setIsFlipped(true)} onMouseLeave={() => setIsFlipped(false)} sx={{}}>
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" flipSpeedBackToFront={1}
                           flipSpeedFrontToBack={0.6}>

                <Stack sx={styles.card}>
                    <Box sx={{
                        borderRadius: "50%",
                        position: "relative",
                        height: {md: "5.88rem", xs: "5.6rem"},
                        width: {md: "5.88rem", xs: "5.6rem"},
                        minHeight: {md: "5.88rem", xs: "5.6rem"},
                        minWidth: {md: "5.88rem", xs: "5.6rem"},
                        backgroundImage: "url(/images/" + picUrl + ")",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}/>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: {md: "1rem", xs: "0.875rem"},
                                color: "#000000",
                                marginTop: "1rem",
                                lineHeight: "1.4rem",
                                textAlign: "center"
                            }}>
                            {desc}
                        </Typography>
                    </Box>
                    <Stack sx={{alignItems: "center", display: "flex", justifyContent:"end", height:"100%"}}>
                        <Typography sx={{
                            fontSize: {md: "1rem", xs: "0.875rem"},
                            lineHeight: {md: "1.4rem", xs: "1.2rem"},
                            color: "#36374A",
                            marginTop: "1.5rem",
                            fontWeight: "600"
                        }}>
                            {name}
                        </Typography>
                        <Typography sx={{
                            fontSize: {md: "1rem", xs: "0.875rem"},
                            lineHeight: {md: "1.4rem", xs: "1.2rem"},
                            color: "#36374A",
                            marginTop: "0.0rem"
                        }}>
                            {title}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack sx={{
                    backgroundImage: "url(/images/" + flipPicUrl + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    ...styles.card
                }}>
                </Stack>
            </ReactCardFlip>
        </Box>
    )
        ;
}

export default TeamProfile;
