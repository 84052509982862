import {createTheme, ThemeOptions} from '@mui/material/styles';
import React from 'react';
import {orange} from '@mui/material/colors';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

let defaultTheme = createTheme()


// @ts-ignore

export const themeOptions = {
    status: {
        danger: orange[500],
    },
    palette: {
        primary: {
            main: '#947EC7',
        },
        secondary: {
            main: '#6B6C89',
        },
        background: {
            paper: '#fff',
            default: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: "'Baloo 2'",
        h1: {
            color: '#947EC7',
            fontFamily: "Baloo",
            fontSize: '3.75rem',
            fontWeight: '400',
            lineHeight: '3.75rem',
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '2rem',
                lineHeight: '2rem',
            },
        },
        h2: {
            color: '#947EC7',
            fontFamily: "Baloo",
            fontSize: '2.25rem',
            lineHeight: '2.625rem',
            fontWeight: '400',
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '1.5rem',
                lineHeight: '1.6rem',
            },
        },
        h3: {
            color: '#434343',
            fontFamily: "'Baloo 2'",
            fontSize: '1.5rem',
            lineHeight: '2rem',
            fontWeight: '400',
        },
        h4: {
            color: '#947EC7',
            fontFamily: "'Baloo 2'",
            fontSize: '2.5rem',
            lineHeight: '2rem',
            fontWeight: '700',
        },
        h6: {
            color: '#000000',
            fontFamily: "'Baloo 2'",
            fontSize: '1.125rem',
            fontWeight: '700',
            lineHeight: '1.5rem',
        },
        body1: {
            color: '#6B6C89',
            fontSize: '1.125rem',
            fontWeight: '400',
            lineHeight: '1.875rem',
            [defaultTheme.breakpoints.down("md")]: {
                fontSize: '1rem',
                lineHeight: '1.5rem',
            },
        },
        body2: {
            color: '#6B6C89',
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        caption: {},
    },
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: false,
            },
        },


        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",

                    width: "100%",
                    flexDirection: "row",
                    right: "auto",
                    alignItems: "flex-end"
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "#F7FF99",

                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: "2.5rem",
                    boxShadow: 'none',
                    minHeight: '3.125rem',
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    fontFamily: "'Baloo 2'"
                },
                contained: {
                    color: '#FFFFFF',
                    backgroundColor: '#947EC7',
                    border: "1px solid #947EC7",
                    '&:hover': {},
                },
                outlined: {
                    color: '#947EC7',
                    backgroundColor: 'transparent',
                    border: "0",
                    textDecoration: "underline",
                    '&:hover': {
                        border: "0",
                        backgroundColor: '#FFFFFF',
                        boxShadow: 'none',
                    },

                }

            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    width: "100%"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused': { // - Set the Input border when parent is focused
                        border: "4px solid rgba(133, 117, 222, 0.2)",
                    },
                    color: "#6B6C89",
                    borderRadius: "2rem",
                    border: "1px solid #E1E1E1",
                    marginBottom: "1rem",
                    paddingLeft: "1rem",
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    [defaultTheme.breakpoints.down("md")]: {
                        fontSize: '0.875rem',
                        lineHeight: '1rem',
                    },
                    color: "red",
                    fontSize: '1rem',
                    lineHeight: "1rem",
                    marginBottom: "0.5rem",
                    textAlign: "left"
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontWeight: '600',
                    fontSize: '1rem',
                    color: "#947EC7",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                    marginLeft: "1rem",
                },
            }
        },


        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#F7FF99',
                        color: "#090038"
                    },
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                    minWidth: "12.31rem",
                    minHeight: "5.25rem",
                    fontFamily: "Cormorant",
                    textTransform: 'none',
                    borderRadius: '2.6rem',
                    boxShadow: 'none',
                    fontWeight: "600",
                    fontSize: "2.375rem",
                    color: '#FFFFFF',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    '&:hover': {
                        backgroundColor: '#8575DE',
                        color: '#fff',
                        boxShadow: 'none',
                    },
                },
            },
        },

    },
};
const theme = createTheme(themeOptions as ThemeOptions);

export default theme;
