import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useSetAtom} from 'jotai';
import {auth} from "./firebase-config";
import {IUser, userAtom} from "./store/userStore";
import {UserInfo} from "firebase/auth";
import {referralAtom} from "./store/referralStore";

const ALLOWED_USERS = ["anti@kofounder.ee", "aive@kofounder.ee", "margus@kofounder.ee", "riho@kofounder.ee", "helen@kofounder.ee"]

export function checkUser(authUser: UserInfo) {
    if (authUser.email && ALLOWED_USERS.includes(authUser.email)) {
        return {email: authUser.email, name: authUser.displayName, photoUrl: authUser.photoURL} as IUser
    }
    return null
}

function AppAuthWrapper({children}: { children: JSX.Element }) {
    const setUser = useSetAtom(userAtom);
    const setReferral = useSetAtom(referralAtom);
    const navigate = useNavigate();
    const location = useLocation();

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const ref = params.get('ref');

    if (ref) {
        setReferral(ref);
    }

    useEffect(() => {
        return auth.onAuthStateChanged(async (authUser) => {
            if (authUser) {
                const user = checkUser(authUser);
                if (user) {
                    setUser(user);

                    if (location.pathname === '/login') {
                        navigate('/admin');
                        return;
                    }
                    navigate(location);
                } else {
                    setUser(null);
                    navigate(location);
                }
            } else {
                setUser(null);
                navigate(location);
            }
        });
    }, []);


    return children;
}

export default AppAuthWrapper;
