import React, {useRef} from 'react';
import {Box, Stack} from "@mui/material";
import HeaderBar from "./app/HeaderBar";
import Footer from "./app/Footer";


function Layout({children}: { children: JSX.Element }) {
    const refs = useRef<any>({});

    return (
        <>
            <Stack sx={{alignItems: "center", width: "100%", overflow: "hidden", minHeight: "calc(100vh - 12rem)"}}
                   ref={(el) => refs.current['home'] = el}>
                <HeaderBar refs={refs}/>
                <Box sx={{height: {xs: "5rem", md: "8rem"}}}/>
                {children}
            </Stack>
            <Footer refs={refs} />
        </>


    )
        ;
}

export default Layout;
