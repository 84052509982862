import React, {useEffect, useState} from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import {IBlogEntry} from "./IBlogEntry";
import {useParams} from "react-router";
import {formatDate} from "../../util/formatUtil";
import ArrowImage from "../../assets/ArrowImage";
import {Link} from "react-router-dom";
import useFireStore from "../../api/useFireStore";
import BlogList from "./BlogList";
import Layout from "../Layout";


function BlogEntry() {
    const {getBlog} = useFireStore();

    const {id} = useParams();
    const [entry, setEntry] = useState<IBlogEntry | null>(null);


    useEffect(() => {
        if (id) {
            loadEntry();
        }
    }, [id]);

    const loadEntry = async () => {
        if (id) {
            const blog = await getBlog(id)
            if (blog) {
                setEntry(blog)
            }
        }
    };

    return (
        <Layout>
            <>
                <Stack sx={{
                    maxWidth: "48.875rem",
                    width: "calc(100% - 2rem)",
                    paddingLeft: {xs: "1rem", md: "4rem"},
                    paddingRight: {xs: "1rem", md: "4rem"},
                }}>
                    {entry ? <Box>
                        <Typography variant={"h2"}
                                    sx={{
                                        textAlign: "left", marginBottom: "1rem", marginTop: "2rem",
                                    }}>{entry.title}</Typography>

                        <Typography variant={"h3"}
                                    sx={{
                                        fontSize: {xs: "1.25rem", md: "1.5rem"},
                                        lineHeight: {xs: "1.5rem", md: "2rem"},
                                        textAlign: "left",
                                        marginBottom: "1rem",
                                        whiteSpace: "pre-line"
                                    }}>{entry.description}</Typography>
                        {entry.picUrl && <Box sx={{display: "inline-block"}}>
                            <img src={entry.picUrl} style={{maxHeight: "35rem", width: "auto", maxWidth: "100%"}}
                                 alt={"Blog image"}/>
                        </Box>}


                        <Box dangerouslySetInnerHTML={{__html: entry.content}}/>
                        <Stack
                            direction={"row"}
                            sx={{
                                padding: "2rem 0 2rem 0",
                                borderTop: "1px solid #C4C4C4",
                                borderBottom: "1px solid #C4C4C4",
                                marginBottom: "2rem",
                            }}>
                            <Box sx={{
                                borderRadius: "50%",
                                position: "relative",
                                height: "4.92rem",
                                width: "4.92rem",
                                minHeight: "4.92rem",
                                minWidth: "4.92rem",
                                backgroundImage: "url(/images/" + entry.authorPicUrl + ")",
                                backgroundSize: "contain",
                            }}/>
                            <Stack sx={{marginLeft: "1rem"}}>
                                <Typography sx={{color: "#434343"}}>
                                    This is an article by <Typography component={"span"}
                                                                      sx={{color: "#5D3EC9"}}>{entry.author}</Typography>
                                </Typography>
                                <Typography sx={{color: "#434343"}}>
                                    {formatDate(entry.date)}</Typography>
                            </Stack>
                        </Stack>
                    </Box> : ""}
                </Stack>

                <Grid container sx={{
                    maxWidth: "85.44rem", width: "100%",
                    paddingLeft: {xs: "1rem", md: "4rem"},
                    paddingRight: {xs: "1rem", md: "4rem"},
                }}>
                    <Grid item xs={6}><Typography variant={"h2"}>Blog</Typography></Grid>
                    <Grid item xs={6} sx={{justifyContent: "end", display: "flex"}}>
                        <Link to={"/blog"}>
                            <Stack direction={"row"} sx={{alignItems: "center", cursor: "pointer"}}>
                                <Typography sx={{marginRight: "0.5rem", fontSize: "1rem"}}>View all</Typography>
                                <ArrowImage width={1}/>
                            </Stack>
                        </Link>
                    </Grid>
                </Grid>

                <BlogList limit={3}/>
                <Box sx={{height: "2rem"}}/>


            </>
        </Layout>
    )
        ;
}

export default BlogEntry;
