import React, {useEffect} from 'react';
import {Box, Dialog, Grid, Link, Stack} from '@mui/material';
import MenuImage from '../../assets/MenuImage';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../theme/theme';
import {useLocation, useNavigate} from 'react-router';
import MenuItems from './MenuItems';
import FacebookImage from '../../assets/FacebookImage';
import LinkedInImage from '../../assets/LinkedInImage';
import RemoveFileImage from '../../assets/RemoveFileImage';
import {ReactComponent as RemoveFileImageWhite} from '../../assets/RemoveFileImageWhite.svg';
import KoThinkerImage from '../../assets/KoThinkerImage';
import {themeAtom} from '../../store/themeStore';
import {useAtomValue} from 'jotai';

function MenuBar({refs, isFooter = false}: { refs?: any; isFooter?: boolean }) {
    const location = useLocation();
    const navigate = useNavigate();

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const [menuOpen, setMenuOpen] = React.useState(false);

    const themeContext = useAtomValue(themeAtom);
    const isDarkTheme = themeContext.mode === 'dark';
    function goTo(anchor: string) {
        if (location.pathname !== '/') {
            navigate('/');
        }
        if (refs) {
            refs.current[anchor].scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    return (
        <>
            <Dialog
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                sx={{
                    display: {xs: 'block', md: 'none'},
                    alignItems: 'start',
                    width: '100%',
                }}
                PaperProps={{
                    elevation: 0,
                    style: {
                        padding: '1.5rem 1rem 0 1rem ',
                        alignItems: 'center',
                        marginTop: 0,
                        margin: 0,
                        alignSelf: 'start',
                        justifySelf: 'start',
                        width: 'calc(100% - 2rem)',
                        minWidth: 'calc(100% - 2rem)',
                        minHeight: '100%',
                        backgroundColor: isDarkTheme ? '#0B001A' : '#FFFFFF',
                    },
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <KoThinkerImage width={6.75}/>
                    </Grid>
                    <Grid item xs={6} sx={{alignItems: 'top', justifyContent: 'end', display: 'flex'}}>
                        <Box sx={{cursor: 'pointer'}} onClick={() => setMenuOpen(false)}>
                            {isDarkTheme ? <RemoveFileImageWhite/> : <RemoveFileImage/>}
                        </Box>
                    </Grid>
                </Grid>
                <Stack
                    sx={{
                        alignItems: 'start',
                        width: '90%',
                        justifyContent: 'left',
                        marginTop: '3rem',
                        marginLeft: '10%',
                    }}
                >
                    <MenuItems refs={refs} isFooter={isFooter}/>
                </Stack>
            </Dialog>

            <Stack sx={{alignItems: 'center', width: '100%', marginBottom: '0.3rem'}}>
                <Grid
                    container
                    sx={{
                        maxWidth: '77.44rem',
                        width: '100%',
                    }}
                >
                    <Grid item xs={6} md={2} sx={{display: 'flex', alignSelf: 'center'}}>
                        <Box>
                            <KoThinkerImage width={md ? 7.69 : 6.75} onClick={() => goTo('home')}/>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={isFooter ? 8 : 10}
                        sx={{display: 'flex', justifyContent: isFooter ? 'center' : 'flex-end'}}
                    >
                        <Box sx={{display: {xs: 'none', md: 'block'}}}>
                            <MenuItems isFooter={isFooter}/>
                        </Box>
                        <Box
                            sx={{display: {xs: 'block', md: 'none', cursor: 'pointer'}}}
                            onClick={() => setMenuOpen(true)}
                        >
                            <MenuImage width={1.1875}/>
                        </Box>
                    </Grid>
                    {isFooter && (
                        <Grid item xs={6} md={2} sx={{display: 'flex', justifyContent: 'right', alignSelf: 'center'}}>
                            <Link target={'_blank'} href={'https://www.facebook.com/YourKoFounder/'}>
                                <FacebookImage/>
                            </Link>
                            <Link
                                target={'_blank'}
                                href={'https://www.linkedin.com/company/kofounder/'}
                                sx={{marginLeft: '2rem'}}
                            >
                                <LinkedInImage/>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            </Stack>
        </>
    );
}

export default MenuBar;
