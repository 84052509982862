import axios from 'axios';
import {IPublicGroupInfo} from "../components/sections/kothinker/model/IPublicGroupInfo";
import {IUpcomingSession} from "../components/sections/kothinker/model/IUpcomingSession";

export const BASE_URL = process.env.REACT_APP_KOTHINKER_URL;

const useKoThinkerApi = () => {


    function getGroups() {
        return axios
            .get(BASE_URL + 'group/formation-info')
            .then((response) => response.data as IPublicGroupInfo[])
            .catch((error) => {
                throw new Error('Error fetching data: ' + error);
            });
    }

    function getUpcomingSessions() {

        return axios
            .get(BASE_URL + 'meeting-sessions/public/global?topicCode=PRODUCT_MANAGEMENT')
            .then((response) => response.data as IUpcomingSession[])
            .catch((error) => {
                throw new Error('Error fetching data: ' + error);
            });
    }


    return {
        getGroups, getUpcomingSessions
    };
};

export default useKoThinkerApi;
