import React from 'react';

function MenuImage({width}: { width: number }) {
    const height = width / 1.1875
    return (<svg width={width + "rem"} height={height + "rem"} viewBox="0 0 19 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.36693 2.93878H18.3465C18.7072 2.93878 18.9996 2.64641 18.9996 2.28571V0.653061C18.9996 0.292367 18.7072 0 18.3465 0H1.36693C1.00623 0 0.713867 0.292367 0.713867 0.653061V2.28571C0.713867 2.64641 1.00623 2.93878 1.36693 2.93878ZM1.36693 9.46939H18.3465C18.7072 9.46939 18.9996 9.17702 18.9996 8.81633V7.18367C18.9996 6.82298 18.7072 6.53061 18.3465 6.53061H1.36693C1.00623 6.53061 0.713867 6.82298 0.713867 7.18367V8.81633C0.713867 9.17702 1.00623 9.46939 1.36693 9.46939ZM1.36693 16H18.3465C18.7072 16 18.9996 15.7076 18.9996 15.3469V13.7143C18.9996 13.3536 18.7072 13.0612 18.3465 13.0612H1.36693C1.00623 13.0612 0.713867 13.3536 0.713867 13.7143V15.3469C0.713867 15.7076 1.00623 16 1.36693 16Z"
                fill="#3A3F74"/>
        </svg>


    );
}

export default MenuImage;
