import {Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography} from "@mui/material";
import Layout from "../../Layout";
import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import CloseAccordionImage from "../../../assets/CloseAccordionImage";
import OpenAccordionImage from "../../../assets/OpenAccordionImage";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme/theme";

interface FAQItem {
    question: string;
    answer: string;
}

function KoThinkerFaq() {

    const [searchParams,] = useSearchParams();

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const faqData: FAQItem[] = [
        {
            question: 'How does KoThinker differ from meetups or trainings?',
            answer: '<p style="margin-top: 0;">KoThinker offers more than just connecting professionals online or teaching you about the latest trends in product management. It provides you with an environment and the motivation to make a meaningful impact by incorporating several essential elements:</p>' +
                '<p><span style="color: #676AA7;"><strong>A systematic approach</strong></span> to personalized growth: Unlike expensive one-size-fits-all training, our learning journey adapts to your unique skill gaps and current challenges. Begin by taking a self-assessment and visually track your progress throughout your journey. Receive situational feedback from your team members who have been in your shoes, ready to provide tailored insights that yield tangible results.</p>' +
                '<p><span style="color: #676AA7;"><strong>Meaningful connections</strong></span> with product experts: You\'ll meet highly experienced product professionals who are as curious and daring as you are. Regular sessions with a clear structure foster in-depth familiarity with each other\'s situations, promoting collaborative problem-solving and authentic connections.</p>' +
                '<p><span style="color: #676AA7;"><strong>Turning new knowledge into practice:</strong></span> We believe in learning by doing, so instead of theory, you\'ll immerse yourself in real-world situations. KoThinker\'s learning journey follows a systematic approach to help you develop a habit of experimentation. During every session, the platform encourages you to create your own on-the-job learning experiments to try out.</p>' +
                '<p><span style="color: #676AA7;"><strong>An engaging upskilling journey:</strong></span> We\'ve all experienced our minds wandering when listening to someone speak for hours, especially online. Discussing personal stories and case studies </p>' +
                '<p><span style="color: #676AA7;"><strong>Affordability:</strong></span> Professional training can be costly. KoThinker provides you with the tools and environment to excel at a great value for your money.</p>',
        },
        {
            question: 'What happens once I sign up?',
            answer: '<p style="margin-top: 0;">After signing up for free and logging into KoThinker, start by completing a few onboarding questions to help us understand your profile for better group matching</p>' +
                '<p>Next, take a knowledge gap self-assessment, the basis for your learning path.</p>' +
                '<p>Then, explore the Session Topics page to see and mark the topics that most relate to you.</p>' +
                '<p>Back on Dashboard, you can join a starting group or opt for the waiting list if there\'s no immediate match.</p>' +
                '<p>If you’re eager to start your upskilling journey earlier, there are recommendations for blogs and books. During the first session, you can choose the most suitable time for upcoming sessions as a group. And remember, you have two co-thinking sessions for free to test the waters, after which you can decide if you wish to continue.</p>',
        },
        {
            question: 'How is my co-thinking group formed?',
            answer: '<p style="margin-top: 0;">All groups consist of a maximum of seven co-thinkers who share your wealth of experience and face challenges specific to your company’s growth phase. Your group will be formed based on product type and role responsibilities, company size and stage, time zone, and language. We understand the importance of choosing the right peers. You can view personal and company profiles of your co-thinkers on the \'My Teammates\' page on the platform. After the first session, you\'ll have the opportunity to confirm your group membership.</p>',
        },
        {
            question: 'How will I find a session time that fits my schedule?',
            answer: '<p style="margin-top: 0;">Once we have announced your group, we suggest meeting times based on your preferences gathered during onboarding questions, including unavailable days and preferred time slots.</p>' +
                '<p>While it may seem mission impossible with a time schedule that probably looks like a wild jungle, remember that if there’s a will, there’s a way.  Plus, having one session twice a month helps you to work more efficiently and make more room in your calendar. </p>',
        },
        {
            question: 'What happens during co-thinking sessions?',
            answer: '<p style="margin-top: 0;">The cumulative result from the group\'s knowledge gap assessment and the session topics you marked will serve as the basis for the learning journey and discussion topics. During these structured sessions, you will establish meaningful connections with product experts who share your passion, address your knowledge gaps by receiving situational feedback on your unique challenges, and exchange the wealth of experiences your group has accumulated. The platform also encourages you to take notes and create on-the-job learning experiments. After each session, you can apply your newfound knowledge in your daily job and share your insights during the next session.</p>',
        },
        {
            question: 'What are some examples of discussion topics?',
            answer: '<p style="margin-top: 0;">You can see the most up to date list of KoThinker session topics and some statistics on Kothinker platform <strong><a href="https://app.kothinker.com/topics" target="_blank">Session topics</a></strong> page. At the bottom of this page, you\'ll find a section where you can suggest your own ideas.</p>' +
                '<p>These are some examples of popular topics:</p>' +
                '<ul>' +
                '<li>How to manage stress during tough periods?</li>' +
                '<li>Competitor analysis - how and why?</li>' +
                '<li>Building your metrics system. Leading, lagging, vanity metrics</li>' +
                '<li>Influence without authority. How PM can create an organizational change</li>' +
                '<li>Building internal stakeholder communication</li>' +
                '<li>How personal biases affect product management</li>' +
                '<li>Setting good success criterias to new feature requests</li>' +
                '<li>Go-to-market with new feature/product</li>' +
                '</ul>'
        },
        {
            question: 'How much time does it take?',
            answer: '<p style="margin-top: 0;">In a month, it takes 2 x 1.5h for the co-thinking sessions and around 2h between the meetings to conduct personal experiments that are directly connected to your job.</p>',
        },
        {
            question: 'What countries do you operate in?',
            answer: '<p style="margin-top: 0;">Our current focus is on Europe, with most participants coming from the United Kingdom, Portugal, Spain, the Netherlands, and Estonia. However, we welcome participants from outside of Europe as well. We will do our best to form suitable groups based on time zones.</p>',
        },
        {
            question: 'What language is used in my group?',
            answer: '<p style="margin-top: 0;">English is used in international co-learning groups. However, if you prefer to learn in your local language, please let us know, and we will try our best to find you a local group. </p>',
        },
        {
            question: 'What if I discover that this is not for me?',
            answer: '<p style="margin-top: 0;">Not to worry. Jumping into new waters may seem scary at first. You have two co-thinking sessions for free to test the waters and get comfortable. After the first month, you can decide whether you wish to continue your upskill journey or not. We surely hope you do! However, if you do not, you can just let us know by the 25th of the previous month that you won’t be continuing your journey next month.</p>',
        },
        {
            question: 'Can I change my group?',
            answer: '<p style="margin-top: 0;">If you feel that the group’s vibes are quite not right for you, you may always choose another group. </p>',
        },
        {
            question: 'Do you have co-thinking groups for professionals other than product people?',
            answer: '<p style="margin-top: 0;">Currently we do not, but we will add groups for other professions in the near future. If you feel like KoThinker is something for you, you may still sign up now and fill out your profile for free. Once we have enough participants for your profession, we will notify you and you may start your self-development journey!</p>',
        },
        {
            question: 'How am I becoming a virtual shareholder by being a community member?',
            answer: '<p style="margin-top: 0;">We are big believers in community. Some actions help us to grow and as a thank you for contributing, we’ll be sharing KoThinker’s virtual shares with you. All together we are giving out 100 000 virtual shares to our community members that, on the 25th of October 2023, represent 5% of KoThinker.</p>' +
                '<p>Some actions that are particularly important to us include being an active participant during KoThinker sessions and spreading the good word with your own network.</p>' +
                '<p>Read more on how to earn virtual shares and what benefits you will receive from becoming a virtual shareholders at <a href="http://kothinker.com/virtualshares" target="_blank">http://kothinker.com/virtualshares</a></p>',
        },
        {
            question: 'Do you organize any additional community events besides group sessions?',
            answer: '<p style="margin-top: 0;">Certainly! In addition to our group sessions, KoThinker organizes various community events to enhance your experience. Our monthly Ko-Readers book club invites members to delve into impactful books collectively. Moreover, the Ko-Creators event serves as a platform where practitioners share their expertise, followed by an engaging Q&A session. These events are open and free for all KoThinker community members, offering valuable learning and networking opportunities beyond our regular sessions.</p>',
        }
    ];

    const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

    const handleAccordionChange = (index: number) => {
        if (expandedIndices.includes(index)) {
            setExpandedIndices(expandedIndices.filter((i: number) => i !== index));
        } else {
            setExpandedIndices([...expandedIndices, index]);
        }
    };

    useEffect(() => {
        if (searchParams.get("page")) {
            const selectedIndex = parseInt(searchParams.get("page")!) as number
            setExpandedIndices([selectedIndex])
        }
    }, [searchParams]);


    return (
        <Layout>
            <Stack sx={{
                maxWidth: "77.44rem",
                width: "100%",
                alignItems: "center",
            }}>
                <Typography variant={"h1"} sx={{
                    textAlign: "center", marginBottom: "3rem",
                    fontSize: {xs: "1.5rem", md: "3.75rem"},
                    lineHeight: {xs: "1.7rem", md: "3.75rem"},
                }}>Frequently asked questions</Typography>
                {faqData.map((faq, index) => (
                    <Accordion
                        key={index}
                        expanded={expandedIndices.includes(index)}
                        onChange={() => handleAccordionChange(index)}
                        className={`accordion ${expandedIndices.includes(index) ? 'expanded' : ''}`}
                        sx={{
                            maxWidth: "45rem",
                            boxShadow: 'none',
                            borderTopLeftRadius: index === 0 ? '2.5rem !important' : '0 !important',
                            borderTopRightRadius: index === 0 ? '2.5rem !important' : '0 !important',
                            backgroundColor: expandedIndices.includes(index) ? '#F2EBFD' : '#FAF6FF',
                            paddingTop: {
                                md: expandedIndices.includes(index) ? '1.5rem' : (index === 0 ? '1.5rem' : '0'),
                                xs: expandedIndices.includes(index) ? '0.5rem' : (index === 0 ? '0.5rem' : '0')
                            },
                            paddingBottom: 0,
                            paddingLeft: "1rem",
                            paddingRight: "1rem",

                        }}
                    >
                        <AccordionSummary expandIcon={
                            <Box
                                sx={{
                                    marginTop: expandedIndices.includes(index) ? '0' : '0.75rem',
                                    marginBottom: expandedIndices.includes(index) ? '0.75rem' : '0rem'
                                }}>
                                {expandedIndices.includes(index) ? <CloseAccordionImage/> : <OpenAccordionImage/>}
                            </Box>
                        }
                                          aria-controls={`faq-${index}-content`}
                                          id={`faq-${index}-header`}
                                          sx={{
                                              margin: 0,
                                              alignItems: 'flex-start',
                                          }}
                        >


                            <Stack sx={{minWidth: "2rem"}}>
                                <Typography sx={{
                                    fontSize: "1.125rem",
                                    fontFamily: "Baloo",
                                    color: "#8B6BAE",
                                    lineHeight: '1.5rem',
                                }}>
                                    {(index + 1) < 10 ? `0${index + 1}` : index + 1}</Typography>
                            </Stack>
                            <Stack sx={{paddingRight: "1rem"}}>
                                <Typography variant="h6">{faq.question}</Typography>
                            </Stack>

                        </AccordionSummary>
                        <AccordionDetails sx={{paddingRight: {md: "3rem", xs: "1rem"}}}>
                            <Stack direction={"row"} sx={{width: "100%"}}>

                                <Stack sx={{paddingRight: "1rem", marginLeft: {md: "2rem", xs: 0}}}>
                                    <Typography
                                        sx={{color: "#322D38"}}
                                        dangerouslySetInnerHTML={{__html: faq.answer}}
                                    />
                                </Stack>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                ))}


                <Box sx={{height: {xs: "3rem", md: "5rem"}}}/>

            </Stack>
        </Layout>
    )
        ;
}

export default KoThinkerFaq;
