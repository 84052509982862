import React from 'react';
import {Box, Stack} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css";

const styles = {
    slide: {
        width: "auto"
    },
};

function KoFounderSwiper({
                             reverse = false,
                             degrees = 5,
                             children
                         }: { reverse?: boolean, degrees?: number, children: JSX.Element }) {

    return (
        <Box sx={{
            width: "calc(100% + 2rem)",
            alignItems: "center",
            transform: 'rotate(' + degrees + 'deg)',
        }}>
            <Swiper
                modules={[Autoplay]} className={reverse ? "reverseconnectdots" : "connectdots"}
                speed={150000}
                initialSlide={3}
                loop={true}
                slidesPerView={"auto"}
                loopedSlides={5}
                loopedSlidesLimit={false}
                updateOnWindowResize={false}
                preloadImages={false}
                autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: false,
                    reverseDirection: reverse
                }}
                style={{
                    width: "100%",
                }}
            >
                <SwiperSlide style={styles.slide}>
                    <Stack direction={"row"}>
                        {children}
                    </Stack>
                </SwiperSlide>

            </Swiper>
        </Box>
    )
        ;
}

export default KoFounderSwiper;
