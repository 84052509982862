import { Box } from '@mui/material';
import React from 'react';
import StellarMan from './StellarMan';

function ForgottenStellarMan1() {
	return (
		<Box
			sx={{
				zIndex: 1,
				position: 'absolute',
				display: { md: 'block', xs: 'none' },
				transform: 'scaleX(-1)',
				right: '432px',
				top: '-31px',
			}}
			id={'stellar-man'}
			className={'stellar-forgotten-1'}
		>
			<Box>
				<StellarMan
					hideRightHand={true}
					hideLeftHand={true}
					rightEyeClassName={'rotate-eye-section-9-top'}
					leftEyeClassName={'rotate-eye-section-9-top'}
					isSmall={true}
				/>
			</Box>
		</Box>
	);
}

export default ForgottenStellarMan1;
