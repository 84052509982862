import React from 'react';
import {Grid, Typography} from "@mui/material";
import KoFounderImage from "../../../assets/KoFounderImage";
import KoThinkerImage from "../../../assets/KoThinkerImage";

function KothinkerMission() {

    return (

        <Grid container sx={{
            maxWidth: "69rem",
            width: "100%",
            paddingLeft: {xs: "1rem", md: "4rem"},
            paddingRight: {xs: "1rem", md: "4rem"},
            alignItems: "center",
        }}>
            < Grid item xs={12} md={4}>
                < Typography sx={{fontSize: "1.3rem", fontWeight: "600", lineHeight: "2.1rem"}}>We
                    are</Typography>
                <KoThinkerImage width={13.19}/>
            </Grid>
            <Grid item xs={12} md={8}>
                <Typography sx={{typography: {marginBottom: "0.5rem"}}} dangerouslySetInnerHTML={{__html: "KoThinker is an innovative co-learning platform where curious and risk-taking product professionals upskill in small and safe co-learning groups. <strong>Sharing personal experiences</strong> and <strong>gaining situational feedback</strong> from your co-thinkers encourages you to create a meaningful impact."}}>

                </Typography>
            </Grid>
        </Grid>
    )
        ;
}

export default KothinkerMission;
