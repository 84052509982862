import React from 'react';
import {IBlogEntry} from "../components/blog/IBlogEntry";
import {collection, doc, getDoc, getDocs, limit, orderBy, query} from "firebase/firestore";
import {firestore} from "../firebase-config";

import {DocumentSnapshot} from "@firebase/firestore";

const useFireStore = () => {


    function convertToBlog(doc: DocumentSnapshot) {
        return {
            title: doc.data()?.title,
            date: new Date(doc.data()?.date.seconds * 1000),
            content: doc.data()?.content,
            id: doc.id,
            description: doc.data()?.description,
            picUrl: doc.data()?.picUrl,
            picFileName: doc.data()?.picFileName,
            author: doc.data()?.author,
            authorPicUrl: doc.data()?.authorPicUrl,
        } as IBlogEntry
    }

    const getBlogs = async (lim?: number) => {
        const bb = [] as IBlogEntry[];
        const cRef = collection(firestore, "blog")
        const q = lim ? query(cRef, orderBy('date', 'desc'), limit(lim)) : query(cRef, orderBy('date', 'desc'))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(doc => {
            const b = convertToBlog(doc)
            bb.push(b);
        });
        return bb
    };

    const getBlog = async (id: string) => {
        const docRef = doc(firestore, "blog", id);
        try {
            const doc = await getDoc(docRef);
            if (doc && doc.data()) {
                return convertToBlog(doc)
            }

        } catch (error) {
            console.log(error)
        }
    };

    return {getBlogs, getBlog};
};

export default useFireStore;
