import React, {useState} from 'react';
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import ArrowImage from "../../assets/ArrowImage";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../theme/theme";
import {useNavigate} from "react-router";

function YourBusiness() {

    const [isFlipped, setIsFlipped] = useState(false)
    const lg = useMediaQuery(theme.breakpoints.up('lg'));

    const navigate = useNavigate();

    const move = () => {
        setIsFlipped(!isFlipped)
    };

    function renderSecretInfo() {
        return <Stack sx={{
            opacity: !lg || isFlipped ? 1 : 0,
            position: {lg: "relative", xs: "relative"},
            padding: {lg: "0rem 3rem 0rem 8rem", md: "0rem 0rem 0rem 0rem", xs: "2rem 2rem 0rem 0rem"},
            transition: "opacity 500ms ease-in",
            alignItems: "start",
            justifyContent: "center",
            height: {md: "100%", xs: "calc(100% - 4rem)"},
        }}>
            <Typography variant={"h1"} sx={{
                textAlign: "left",
                fontSize: {xs: "1.5rem", md: "3.75rem"},
                lineHeight: {xs: "1.7rem", md: "3.75rem"},
            }}>Meaningful Impact</Typography>

            <Grid container spacing={0} sx={{width: '100%', mt: "1.5rem"}}>
                <Grid item xs={12} sx={{marginBottom: "1rem"}}>
                    <Typography>
                        Living a meaningful life involves experiencing a sense of belonging and contributing to
                        something greater than oneself. To expand the scope of life, we must learn how to create an
                        impact.
                    </Typography>
                </Grid>
            </Grid>


            <Box>
                {lg &&
                    <Button sx={{marginTop: "1.5rem", marginLeft: "1rem"}} variant={"outlined"} onClick={() => move()}>
                        <Typography sx={{marginRight: "1rem", fontWeight: "600"}}>
                            Back
                        </Typography>
                    </Button>}
            </Box>
        </Stack>


            ;
    }


    function renderPicture() {
        return <Box sx={{
            height: {md: "25rem", xs: "13.125rem"},
            right: {lg: isFlipped ? "100%" : "0%", xs: 0},
            top: {lg: "calc(50% - 12.5rem)", xs: 0},
            position: {lg: "absolute", xs: "relative"},
            backgroundImage: "url(/images/vision.webp)",
            width: "100%",
            backgroundRepeat: "no-repeat",
            borderRadius: {md: "31.25rem", xs: "13.75rem"},
            backgroundPosition: "center",
            backgroundSize: "cover",
            transition: "right 500ms ease-in",
            zIndex: 2
        }}/>
    }

    function renderOriginalContent() {
        return <>
            <Typography variant={"h1"} sx={{
                textAlign: "left",
                fontSize: {xs: "1.5rem", md: "3.75rem"},
                lineHeight: {xs: "1.7rem", md: "3.75rem"},
            }}>Our Vision</Typography>
            <Typography sx={{marginTop: "1rem", fontWeight: "700"}}>
                Our vision is to ensure every professional feels encouraged to create a meaningful impact.
            </Typography>

            <Stack direction={"row"} sx={{width: "100%", display: "flex", alignItems: {xs: "center", lg: "start"}}}>
                <Button sx={{marginTop: "1.5rem"}} variant={"contained"}
                        onClick={() => window.location.href = 'https://kothinker.com/blog/why-kothinker-was-born'
                        }>
                    Our story<ArrowImage
                    width={1.25}/>
                </Button>
                {lg && <Button sx={{marginTop: "1.5rem", marginRight: "0.0rem"}} variant={"outlined"}
                               onClick={() => move()}>
                    Tell me more
                    <ArrowImage width={1.25}/>
                </Button>}
            </Stack>
        </>
    }

    return (
        <Grid container sx={{
            maxWidth: "85.44rem", width: "100%", paddingLeft: {md: "4rem", xs: "1rem"},
            paddingRight: {md: "4rem", xs: "1rem"}
        }}>
            <Grid item xs={12} lg={6} order={{lg: 1, xs: 2}} sx={{
                padding: {
                    lg: "4rem 3rem 3rem 8rem",
                    md: "4rem 0rem 3rem 0rem",
                    xs: "2rem 2rem 0rem 0rem",
                },
                opacity: lg && isFlipped ? 0 : 1,
                transition: "opacity 500ms ease-in",
            }}>
                {renderOriginalContent()}

            </Grid>

            <Grid item xs={12} lg={6} order={{lg: 2, xs: 1}} sx={{
                alignItems: "center", position: "relative"
            }}>
                {renderPicture()}
                {lg && renderSecretInfo()}
            </Grid>
            <Grid item xs={12} lg={6} order={3} sx={{
                alignItems: "center", position: "relative", display: {lg: "none", xs: "block"},
            }}>
                {renderSecretInfo()}
            </Grid>
        </Grid>
    )
        ;
}

export default YourBusiness;
