import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StellarMan from './StellarMan';

function ForgottenStellarMan2() {
	const [isVisible, setIsVisible] = useState(false);

	// If block with big percentage comes into view, focus stellar eyes on that element.
	function handleStellarFadeIn() {
		const blockElement = document.getElementById('section-9-bottom');
		if (blockElement) {
			const block = blockElement.getBoundingClientRect();
			// If section's bottom part is in view
			if (block.bottom <= window.innerHeight) {
				setIsVisible(true);
			}
		}
	}

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('scroll', handleStellarFadeIn);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('scroll', handleStellarFadeIn);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			sx={{
				zIndex: 1,
				position: 'absolute',
				display: { md: 'block', xs: 'none' },
				right: '150px',
				top: '168px',
				transition: 'all 1s ease-in-out',
			}}
			id={'stellar-man'}
			className={isVisible ? 'section-9-move' : ''}
		>
			<Box className={'angle-stellar-man'}>
				<StellarMan
					rightEyeClassName={'rotate-eye-section-9-bottom'}
					leftEyeClassName={'rotate-eye-section-9-bottom'}
					leftHandClassName={isVisible ? 'rotate-left-hand-section-9-bottom' : ''}
					rightHandClassName={isVisible ? 'rotate-right-hand-section-9-bottom' : ''}
					isSmall={true}
				/>
			</Box>
		</Box>
	);
}

export default ForgottenStellarMan2;
