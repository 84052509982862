import React, {useEffect} from 'react';
import {Button, Link} from '@mui/material';
import {Link as ReactLink} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router';
import {themeAtom} from '../../store/themeStore';
import {useAtomValue} from 'jotai';
import {referralAtom} from "../../store/referralStore";

function MenuItems({isFooter = false, refs}: { isFooter?: boolean; refs?: any }) {
    const theme = useAtomValue(themeAtom);
    const isDarkMode = theme.mode === 'dark';
    const referral = useAtomValue(referralAtom);

    const menuStyle = {
        menu: {
            marginBottom: {md: 0, xs: '1.5rem'},
            position: 'relative',
            minWidth: 'auto',
            minHeight: '2rem',
            padding: 0,
            marginLeft: '1rem',
            marginRight: '1rem',
            fontWeight: {md: '600', xs: '400'},
            fontSize: {md: '1rem', xs: '1.25rem'},
            color: isDarkMode ? '#FFF' : 'rgba(0, 0, 0, 0.6)',
            textTransform: 'none',
            borderRadius: '0',
            backgroundColor: 'transparent',
            borderBottom: {xs: 0, md: '2px solid transparent'},

            '&:hover': {
                ':after': {
                    height: '2px',
                    width: '100%',
                    backgroundColor: '#947EC7',
                    content: '""',
                    borderRadius: '3px',
                    bottom: '0%',
                    position: 'absolute',
                },
                backgroundColor: 'transparent',
            },
        },
    };

    const activeStyle = {
        menu: {
            ...menuStyle.menu,
            fontWeight: {md: '600', xs: '600'},
            opacity: '1',
            color: isDarkMode ? '#9730F3' : '#947EC7',
            '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
            ':after': {
                height: '2px',
                width: '100%',
                backgroundColor: isDarkMode ? '#9730F3' : '#947EC7',
                content: '""',
                borderRadius: '3px',
                bottom: '0%',
                position: 'absolute',
            },
        },
    };
    const location = useLocation();
    const navigate = useNavigate();

    function goTo(anchor: string) {
        if (location.pathname !== '/') {
            navigate('/');
        }
        if (refs) {
            refs.current[anchor].scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    return (
        <>
            <Button onClick={() => goTo('home')} sx={location.pathname === '/' ? activeStyle.menu : menuStyle.menu}>
                Welcome
            </Button>

            <ReactLink to={'/solution'}>
                <Button sx={location.pathname.startsWith('/solution') ? activeStyle.menu : menuStyle.menu}>
                    Solution
                </Button>
            </ReactLink>

            <ReactLink to={'/faq'}>
                <Button sx={location.pathname.startsWith('/faq') ? activeStyle.menu : menuStyle.menu}>FAQ</Button>
            </ReactLink>

            <ReactLink to={'/blog'}>
                <Button sx={location.pathname.startsWith('/blog') ? activeStyle.menu : menuStyle.menu}>Insights</Button>
            </ReactLink>

            <ReactLink to={'/team'}>
                <Button sx={location.pathname === '/team' ? activeStyle.menu : menuStyle.menu}>Team</Button>
            </ReactLink>

            <ReactLink to={'/contact'}>
                <Button sx={location.pathname === '/contact' ? activeStyle.menu : menuStyle.menu}>Get in touch</Button>
            </ReactLink>

            {!isFooter &&
                <Link href={referral ? 'https://app.kothinker.com?ref=' + referral : 'https://app.kothinker.com'}>
                    <Button
                        sx={{
                            ...menuStyle.menu,
                            color: '#6b5b91',
                        }}
                    >
                        Log in
                    </Button>
                </Link>}

            {isFooter && <ReactLink to={'/virtualshares'}>
                <Button sx={location.pathname === '/virtualshares' ? activeStyle.menu : menuStyle.menu}>Virtual
                    shares</Button>
            </ReactLink>}
        </>
    );
}

export default MenuItems;
