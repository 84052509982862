import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StellarText } from '../SolutionStory';
import StellarMan from './StellarMan';
import { stellarStyles } from '../SolutionStory';

function WhyStellarMan() {
	const [isVisible, setIsVisible] = useState(false);

	// If block with big percentage comes into view, focus stellar eyes on that element.
	function handleStellarFadeIn() {
		const blockElement = document.getElementById('section-7');
		if (blockElement) {
			const block = blockElement.getBoundingClientRect();
			// If section's bottom part is in view
			if (block.bottom - 150 <= window.innerHeight) {
				setIsVisible(true);
			}
		}
	}

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('scroll', handleStellarFadeIn);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('scroll', handleStellarFadeIn);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			sx={{
				position: 'absolute',
				zIndex: 1,
				display: { xs: 'none', md: 'flex' },
				bottom: 0,
				right: '2rem',
			}}
			className={isVisible ? 'section-7-fade-in-bottom' : 'stellar-hidden'}
		>
			<Typography
				sx={{
					...stellarStyles.h2,
					fontSize: '3.125rem',
					lineHeight: '100%',
					fontWeight: 700,
					pt: '12rem',
				}}
			>
				But <StellarText text={'why?'} fontSize={'3.125rem'} />
			</Typography>
			<Box sx={{ marginLeft: '-1rem' }}>
				<Box
					sx={{
						zIndex: 1,
						display: { md: 'block', xs: 'none' },
						marginRight: '-1rem',
						position: 'relative',
						// top: '-4rem',
					}}
					id={'stellar-man'}
					className={isVisible ? 'section-7-fade-in-bottom' : 'stellar-hidden'}
				>
					<Box className={'why-stellar-man'}>
						<StellarMan
							rightEyeClassName={isVisible ? 'rotate-eye-section-7-bottom' : ''}
							leftEyeClassName={isVisible ? 'rotate-eye-section-7-bottom' : ''}
							isSmall={true}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default WhyStellarMan;
