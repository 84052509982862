import React from 'react';
import {themeAtom} from "../store/themeStore";
import {  useAtomValue } from 'jotai';

function KoThinkerImage({width, onClick}: { width: number, onClick?: any }) {
    const themeContext = useAtomValue(themeAtom);

    const isDarkTheme = themeContext.mode === 'dark';

    const fillColor =isDarkTheme ? '#FFF' : '#8C6BAF';
    const height = width / 3.844
    return (<svg onClick={onClick} width={width + "rem"} height={height + "rem"} viewBox="0 0 130 30" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_606_950)">
                <path
                    d="M0.845703 1.55045H6.03026V13.642H6.11635C6.99791 11.0268 8.82468 5.35181 10.0787 1.55045H15.9764L11.3121 12.2952L16.1542 29.3051H10.367L7.62494 17.6003L6.03401 21.1494V29.3126H0.84945L0.845703 1.55045Z"
                    fill={fillColor}/>
                <path d="M51.5387 2.99066H46.9961V1.52054H57.5992V2.99066H53.139V29.3051H51.5387V2.99066Z"
                      fill={fillColor}/>
                <path
                    d="M59.8184 1.52054H61.4205V13.6009H68.4113V1.52054H70.0134V29.3051H68.4113V15.0691H61.4205V29.3051H59.8184V1.52054Z"
                    fill={fillColor}/>
                <path d="M76.0493 1.52054V29.3051H74.4434V1.52054H76.0493Z" fill={fillColor}/>
                <path
                    d="M80.5332 29.3051V1.52054H82.5921C84.4918 7.91282 88.9258 23.3481 89.5435 26.4321H89.6277C89.5004 22.3692 89.4181 18.4595 89.4181 13.967V1.52054H91.0128V29.3051H88.9932C86.895 22.1526 82.6145 6.80697 81.834 3.52677H81.7928C81.9594 6.85554 82.1223 11.9832 82.1223 17.0156V29.3051H80.5332Z"
                    fill={fillColor}/>
                <path
                    d="M95.494 1.52054H97.0905V15.792H97.1317C98.3782 13.33 102.017 5.91593 104.089 1.52054H105.773L100.199 12.7827L106.432 29.3051H104.701L99.1981 14.4658L97.0905 18.495V29.3051H95.4902L95.494 1.52054Z"
                    fill={fillColor}/>
                <path
                    d="M116.182 15.0691H109.818V27.8331H116.931L116.674 29.3014H108.203V1.52054H116.556V2.99066H109.818V13.6046H116.182V15.0691Z"
                    fill={fillColor}/>
                <path
                    d="M121.186 15.7715V29.3051H119.584V1.52054H123.34C127.469 1.52054 129.073 3.42963 129.073 7.60647V9.10086C129.073 12.3661 128.179 14.3854 126.24 15.0037C128.151 15.5492 128.86 17.0398 128.86 20.703C128.86 23.1146 128.819 27.4838 129.15 29.3107H127.552C127.219 27.5249 127.26 22.7466 127.26 20.8711C127.26 17.0548 126.68 15.7771 123.649 15.7771L121.186 15.7715ZM121.186 14.3107H123.857C126.884 14.3107 127.43 12.0953 127.43 9.18119V7.65317C127.43 4.71855 126.483 2.98318 123.331 2.98318H121.186V14.3107Z"
                    fill={fillColor}/>
                <path
                    d="M31.2718 4.48319C33.356 4.48319 35.3933 5.09998 37.1262 6.25558C38.8591 7.41118 40.2097 9.05369 41.0073 10.9754C41.8048 12.8971 42.0135 15.0117 41.6069 17.0517C41.2003 19.0918 40.1967 20.9657 38.723 22.4365C37.2493 23.9073 35.3717 24.9089 33.3276 25.3147C31.2835 25.7205 29.1648 25.5123 27.2393 24.7163C25.3138 23.9203 23.668 22.5723 22.5102 20.8428C21.3523 19.1133 20.7343 17.08 20.7343 15C20.7377 12.2118 21.849 9.53884 23.8245 7.56731C25.7999 5.59577 28.4781 4.48665 31.2718 4.48319ZM31.2718 0C28.2992 0 25.3934 0.879729 22.9218 2.52795C20.4502 4.17617 18.5238 6.51885 17.3863 9.25974C16.2487 12.0006 15.9511 15.0166 16.531 17.9264C17.1109 20.8361 18.5423 23.5088 20.6443 25.6066C22.7462 27.7044 25.4242 29.133 28.3397 29.7118C31.2551 30.2906 34.2771 29.9935 37.0234 28.8582C39.7697 27.7229 42.117 25.8003 43.7685 23.3336C45.42 20.8668 46.3015 17.9667 46.3015 15C46.3015 13.0302 45.9127 11.0796 45.1574 9.25974C44.4021 7.43986 43.295 5.78627 41.8994 4.39339C40.5038 3.00051 38.8469 1.89562 37.0234 1.1418C35.1999 0.387978 33.2455 0 31.2718 0Z"
                    fill={fillColor}/>
            </g>
            <defs>
                <clipPath id="clip0_606_950">
                    <rect width="128.308" height="30" fill="white" transform="translate(0.845703)"/>
                </clipPath>
            </defs>
        </svg>


    );
}

export default KoThinkerImage;
