import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import ArrowImage from "../../assets/ArrowImage";
import {useNavigate} from "react-router";
import useFireStore from "../../api/useFireStore";
import {IBlogEntry} from "./IBlogEntry";
import BlogList from "./BlogList";
import Layout from "../Layout";

function Blog() {
    const {getBlogs} = useFireStore();
    const navigate = useNavigate();
    const [firstPost, setFirstPost] = useState<IBlogEntry>()


    const loadBlogs = async () => {
        let bb = await getBlogs(1)
        if (bb && bb.length > 0) {
            setFirstPost(bb[0])
        }
    };

    useEffect(() => {
        loadBlogs()
    }, []);

    function naviToFirst() {
        if (firstPost) {
            navigate("/blog/" + firstPost.id);
        }
    }


    return (
        <Layout>
            <>
                <Grid container sx={{
                    maxWidth: "85.44rem", width: "100%",
                    paddingLeft: {xs: "1rem", md: "4rem"},
                    paddingRight: {xs: "1rem", md: "4rem"},
                }}>
                    <Grid item md={6} xs={12} sx={{alignItems: "center", position: "relative"}}>

                        <Box sx={{
                            backgroundImage: `url("${firstPost?.picUrl}")`,
                            height: {md: "25rem", xs: "13.125rem"},
                            width: "100%",
                            position: "relative",
                            backgroundRepeat: "no-repeat",
                            borderRadius: {md: "31.25rem", xs: "13.75rem"},
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            zIndex: 2
                        }}/>

                    </Grid>
                    <Grid item md={6} xs={12}
                          sx={{
                              alignItems: "center",
                              justifyItems: "center",
                              padding: {md: "4rem 8rem 3rem 3rem", xs: "2rem 2rem 0rem 0rem"}
                          }}>
                        <Typography variant={"h2"} sx={{
                            textAlign: "left",
                        }}>{firstPost?.title}</Typography>
                        <Typography sx={{
                            marginTop: "1.5rem",
                            typography: {md: "body1", xs: "body2"}
                        }}>{firstPost?.description}</Typography>
                        <Stack sx={{width: "100%", display: "flex", alignItems: {xs: "center", md: "start"}}}>

                            <Button sx={{marginTop: "1.5rem"}} variant={"contained"} onClick={() => naviToFirst()}>
                                Read more <ArrowImage
                                width={1.25}/>
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <BlogList/>
                <Box sx={{height: "2rem"}}/>
            </>
        </Layout>
    );
}

export default Blog;
