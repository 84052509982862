import React from 'react';
import './App.css';
import {ThemeProvider} from "@mui/material";
import theme from "../theme/theme";
import AppRoutes from "./AppRoutes";
import AppAuthWrapper from "../../AppAuthWrapper";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {

    return (
        <>
            <AppAuthWrapper>
                <ThemeProvider theme={theme}>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <AppRoutes/>
                </ThemeProvider>
            </AppAuthWrapper>
        </>
    )
        ;
}

export default App;
