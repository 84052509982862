import React, {useState} from 'react';
import {Box, Grid, Stack, Typography, useMediaQuery} from '@mui/material';
import SolutionStoryLayout from './SolutionStoryLayout';
import {ReactComponent as ScrollDownImage} from '../../assets/solutions-scroll-down.svg';
import HeaderStellarMan from './StellarMan/HeaderStellarMan';
import VideoStellarMan from './StellarMan/VideoStellarMan';
import AngleStellarMan from './StellarMan/AngleStellarMan';
import WhyStellarMan from './StellarMan/WhyStellarMan';
import ForgottenStellarMan1 from './StellarMan/ForgottenStellarMan1';
import ForgottenStellarMan2 from './StellarMan/ForgottenStellarMan2';
import AgendaItems from '../AgendaItems';
import GroupSelection from "../sections/kothinker/GroupSelection";
import StellarMan from "./StellarMan/StellarMan";

const headingFontSize = { md: '3.125rem', xs: '2.125rem' };
export const stellarStyles = {
	h2: {
		color: '#E9DEFF',
		fontSize: headingFontSize,
		paddingBottom: '1rem',
		lineHeight: '100% !important',
	},
	body: {
		color: '#FFF',
		fontFamily: "'Baloo 2'",
		fontSize: '1.25rem',
	},
	bodyPink: {
		color: '#E9DEFF',
		fontFamily: "'Baloo 2'",
		fontSize: '1.25rem',
	},
	bodySmall: {
		color: '#FFF',
		fontFamily: "'Baloo 2'",
		fontSize: '1.125rem',
	},
	bodySmallPink: {
		color: '#E9DEFF',
		fontFamily: "'Baloo 2'",
		fontSize: '1.125rem',
	},
	bodyBig: {
		color: '#FFF',
		fontFamily: "'Baloo 2'",
		fontSize: '1.125rem',
	},
	bodyDark: {
		color: '#1E0044',
		fontFamily: "'Baloo 2'",
		fontSize: '1.125rem',
		lineHeight: '160%',
	},
	stellar: {
		fontFamily: 'Georiga',
		fontStyle: 'italic',
		color: '#8338ED',
		fontSize: '1.75rem',
	},
	koThinker: {
		color: '#8338ED',
		fontFamily: "'Baloo 2'",
		fontWeight: 700,
		fontSize: '1.75rem',
	},
	section: {
		mx: '1.25rem',
		my: '2.5rem',
		borderRadius: '1.25rem',
		p: { xs: '1.25rem', md: '2.5rem' },
	},
	borderSection: {
		pt: '1.25rem',
		pb: '1.25rem',
		borderBottom: '1px solid rgba(255, 255, 255, 0.40)',
		color: '#FFF',
		fontFamily: "'Baloo 2'",
		fontSize: '1.25rem',
	},
	headingStyle: {
		color: '#E9DEFF',
		fontSize: '4.5rem',
		fontWeight: 500,
		fontFamily: "'Baloo 2'",
		lineHeight: '4.5rem',
		letterSpacing: { md: '-0.225rem', xs: 'auto' },
	},
	leftPaddingOnly: {
		py: { xs: '1.25rem', md: '2.5rem' },
		pl: { xs: '1.25rem', md: '2.5rem' },
		pr: { xs: '1.25rem', md: 0 },
	},
	purpleBox: {
		background: '#9B57FA',
		padding: '1.88rem',
		borderRadius: '1.125rem',
		maxWidth: '15rem',
	},
	purpleBoxText: {
		color: '#1E0044',
		fontFamily: "'Baloo 2'",
		fontSize: '1.125rem',
	},
	footerText: {
		fontSize: '0.875rem',
		fontFamily: 'Montserrat',
		color: '#E9DEFF',
		lineHeight: '160%',
	},
	outcomeText: {
		color: '#E9DEFF',
		fontFamily: "'Baloo 2'",
		fontWeight: 500,
		fontSize: '1.75rem',
	},
	outcomeHighlightText: {
		color: '#220445',
		fontFamily: 'Georiga',
		fontWeight: 400,
		fontStyle: 'italic',
		fontSize: '1.75rem',
	},
};

function SolutionStory() {
	return (
		<SolutionStoryLayout>
			<Box>
				<HeaderStellarMan />
				<SectionOne />
				<SectionTwo />
				<SectionThree />
				<SectionFour />
				<SectionFive />
				<SectionSix />
				<SectionSeven />
				<SectionEight />
				<SectionNine />
				<SectionTen />
				<SectionOutcomes />
				<SectionEleven />
			</Box>
		</SolutionStoryLayout>
	);
}

function SectionOne() {
	return (
		<Box
			sx={{
				...stellarStyles.section,
				mt: '0.75rem',
				backgroundColor: '#1e0044',
				pt: { md: '10.5rem', xs: '7.5rem' },
			}}
		>
			<Grid container>
				<Grid item xs={12} md={7}>
					<Typography variant={'h1'} color={'#E9DEFF'} sx={stellarStyles.headingStyle}>
						Can you relate to {' '}
						<Box
							component={'span'}
							sx={{
								fontFamily: 'Georiga',
								fontStyle: 'italic',
								paddingTop: `4.38rem`,
								fontSize: { md: `6.25rem`, xs: '3.25rem' },
								background: '-webkit-linear-gradient(93deg, #8338EC 49.3%, #B923FF 87.76%)',
								backGroundClip: 'text',
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent',
								letterSpacing: '-0.3125rem;',
								paddingRight: '0.3225rem',
								marginLeft: '0.3rem',
							}}
						>
							{' '}
							Stellar
						</Box>
						{', '}a growth minded product builder?
					</Typography>
					<Typography color={'#E9DEFF'} sx={{ fontSize: '1.5rem', pt: '1.5rem', lineHeight: '1.8rem' }}>
						She always strives to be better at her job and make an impact.
					</Typography>
					<Box sx={{ mt: { md: '3.75rem', xs: '1.75rem' }, cursor: 'pointer', width: 'fit-content' }}>
						<ScrollDownImage />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

function SectionTwo() {
	return (
		<Box
			sx={{
				...stellarStyles.section,
				p: 0,
				background: 'linear-gradient(174deg, #161E6D 4.95%, #452E93 25.7%, #DF44AE 61.34%, #E48091 107.09%)',
			}}
		>
			<Grid container>
				<Grid item xs={12} md={6}>
					<Box sx={{ ...stellarStyles.leftPaddingOnly, pb: '1rem !important' }}>
						<Typography
							variant={'h2'}
							color={'#FFF'}
							sx={{
								fontSize: '3rem',
								lineHeight: '3rem',
								paddingBottom: '2.56rem',
								fontFamily: "'Baloo 2'",
							}}
						>
							<StellarText text={'Stellar'} fontSize={{ md: '3rem', xs: '1.5rem' }} isWhite={true} /> 's
							journey is like a <strong>carousel.</strong> Swift, with its <strong>highs and lows</strong>
						</Typography>
						<Typography sx={{ ...stellarStyles.bodySmall, lineHeight: '1.575rem' }}>
							Sometimes she <strong>feels lonely</strong> as her colleagues don't share her passion for
							product management and its unlimited potential for business growth.{' '}
							<strong>Attending conferences, meetups and following LinkedIn influencers</strong> is indeed
							inspiring, but they often don't address the specific challenges Stellar faces today.
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box
						sx={{
							overflow: 'hidden',
							borderTopRightRadius: '1.25rem',
							borderBottomRightRadius: '1.25rem',
							height: '100%',
							width: '100%',
							backgroundImage: "url('/images/solution-bg-1.webp')",
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

function SectionThree() {
	return (
		<Box
			id={'percentage-box'}
			color={'#FFFFFF'}
			sx={{
				...stellarStyles.section,
				backgroundImage: "url('/images/solution-bg-2.webp')",
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				pb: '5rem !important',
			}}
		>
			<Grid container>
				<Grid item xs={12} md={6}>
					<Typography
						color={'#8338ED'}
						sx={{
							fontFamily: 'Baloo',
							fontSize: { md: '10rem', xs: '6rem' },
							lineHeight: '100% !important',
						}}
						id={'percentage'}
					>
						12%
					</Typography>
					<Typography
						variant="h2"
						color={'#0B001A'}
						sx={{ fontSize: '1.75rem', fontFamily: "'Baloo 2'", maxWidth: '27rem' }}
					>
						of employees actually apply the new skills they've learned on the job
					</Typography>
					<Typography color={'#0B001A'} sx={{ pt: '1.5rem', fontSize: '1.125rem', maxWidth: '31.25rem' }}>
						Who could <strong style={{ color: '#8338ED' }}>motivate</strong> Stellar to translate new
						knowledge into action and provide{' '}
						<strong style={{ color: '#8338ED' }}>situational feedback?</strong>
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}

function SectionFour() {
	function Tab({ title }: { title: string }) {
		return (
			<Box
				id={'stopping-box'}
				color={'#E9DEFF'}
				sx={{
					border: '1px solid #E9DEFF',
					p: '0.37rem 0.74rem',
					borderRadius: '1.25rem',
					width: 'fit-content',
				}}
			>
				{title}
			</Box>
		);
	}

	function HalfBold({ text, boldText }: { text: string; boldText: string }) {
		return (
			<>
				<Typography sx={stellarStyles.bodySmall}>
					{text} <strong style={{ color: '#FFF' }}>{boldText}</strong>
				</Typography>
			</>
		);
	}

	return (
		<Box
			color={'#FFFFFF'}
			sx={{
				...stellarStyles.section,
				backgroundImage: "url('/images/solution-bg-3.webp')",
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
		>
			<Typography variant={'h2'} sx={stellarStyles.h2}>
				<StellarText text={'Stellar'} fontSize={{ md: '3rem', xs: '2.125rem' }} /> is looking for
			</Typography>
			<Box
				sx={{
					display: 'flex',
					gap: '0.5rem',
					mt: '1.5rem',
					mb: '1.5rem',
					flexWrap: 'wrap',
				}}
			>
				<Tab title={'As driven'} />
				<Tab title={'Like minded'} />
				<Tab title={'Co-thinking people'} />
			</Box>
			<Box>
				<Box sx={{ paddingBottom: '2rem' }}>
					<HalfBold text={'People with'} boldText={'understanding of the product and the business'} />
					<HalfBold text={'People with'} boldText={'diverse and varied experiences'} />
					<HalfBold text={'People with'} boldText={'desire to invest in their learning journey'} />
				</Box>
				<Typography color={'#E9DEFF'} sx-={{ lineHeight: '1.8rem' }}>
					<StellarText text={'Stellar'} fontSize={'1.125rem'} /> is in search of{' '}
					<strong style={{ color: '#FFFFFF' }}>meaningful connections.</strong>
				</Typography>
			</Box>
		</Box>
	);
}

function SectionFive() {
	return (
		<Box sx={{ m: { md: '3.75rem', xs: '1.75rem', position: 'relative' } }}>
			<Grid container direction={{ xs: 'column', md: 'row' }}>
				<Grid item xs={12} md={6} sx={{pb: { xs: '2rem', md: '4rem' }}}>
					<Box sx={{ maxWidth: '30rem', py: { xs: '2rem', md: '4rem' } }}>
						<Typography
							sx={{
								...stellarStyles.borderSection,
								borderTop: '1px solid rgba(255, 255, 255, 0.40)',
							}}
							className={'conference-1'}
						>
							<StellarText text={'Stellar'} fontSize={'1.75rem'} /> discovered{' '}
							<KoThinkerText text={'KoThinker'} fontSize={'1.75rem'} />, a mastermind platform designed to
							elevate professional impact.
						</Typography>
						<Typography
							sx={{
								...stellarStyles.borderSection,
								py: { xs: stellarStyles.borderSection.pb, md: '3rem' },
							}}
							className={'conference-2'}
						>
							<KoThinkerText text={'KoThinker'} fontSize={'1.75rem'} /> helps{' '}
							<StellarText text={'Stellar'} fontSize={'1.75rem'} /> to find the missing puzzle piece:{' '}
							<strong>a small and safe group of co-thinkers.</strong>
						</Typography>
						<Typography sx={stellarStyles.borderSection} className={'conference-3'}>
							<KoThinkerText text={'KoThinker'} fontSize={'1.75rem'} /> does more than connecting, though.
							It goes through the Maslow's hierarchy of needs.
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} alignSelf={"center"} sx={{p: { xs: '0rem', md: '2rem' }, mb: '2rem'}}>
					<img src={'/images/maslow.png'} alt={'conference'} style={{ width: '100%' }} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Box sx={{ maxWidth: '30rem', pb: { xs: 0, md: '12rem'}, pr: { xs: 0, md: '2rem'}  }} id={'section-five'}>
						<Typography sx={stellarStyles.body}>
							First, the sense of belonging. The welcoming session is full of elements for getting to know
							each other better. <br /> <StellarText text={'Stellar'} fontSize={'1.75rem'} /> enjoys
							sharing her most embarrassing product failure story and describing her strengths through
							some engaging metaphors.
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						width: '100%',
						top: '8rem',
						position: { xs: 'static', md: 'sticky' },
						height: 'fit-content',
						pt: { xs: '1rem', md: 0 },
					}}
				>
					<VideoStellarMan />
					<img src={'/images/solution-bg-5.png'} alt={'conference'} style={{ width: '100%' }} />
				</Grid>
			</Grid>
		</Box>
	);
}

function SectionSix() {
	return (
		<Box sx={{ ...stellarStyles.section, p: 0, background: 'linear-gradient(180deg, #1E0044 0%, #9B57FA 100%)' }}>
			<Grid container>
				<Grid item md={6} xs={12} sx={stellarStyles.leftPaddingOnly}>
					<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
						<Typography component={'h2'} sx={{ ...stellarStyles.h2, fontFamily: 'Baloo' }}>
							Preparing for first session
						</Typography>
						<Typography
							sx={{
								...stellarStyles.bodyPink,
								marginTop: 'auto',
								lineHeight: '160%',
								maxWidth: '31.25rem',
								pb: { xs: 0, md: '2rem' },
							}}
						>
							As she prepared for the first session,{' '}
							<StellarText text={'Stellar'} isWhite={true} fontSize={'1.125rem'} /> took the KoThinker{' '}
							<strong>self-assessment to analyse her emotional and technical skills</strong> which are
							essential for being an effective Product Builder.
						</Typography>
					</Box>
				</Grid>
				<Grid item md={6} xs={12}>
					<Box
						sx={{
							maxHeight: { xs: 'unset', md: '36rem' },
							overFlow: 'hidden',
							py: '2.5rem',
							mb: { xs: '-10rem', md: '-5rem' },
						}}
					>
						<Box
							sx={{
								position: 'relative',
								top: { xs: '-5rem', md: '-7rem' },
							}}
						>
							<img src={'/images/solution-bg-6.svg'} alt={'conference'} style={{ width: '100%' }} />
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
function SectionOutcomes() {
	const [position, setPosition] = useState({ x: 0, y: 0 });
	// @ts-ignore
	const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
	// @ts-ignore
	const handleMouseMove = (event) => {
		setPosition({ x: event.clientX, y: event.clientY });
	};
	return (
		<Box sx={{ ...stellarStyles.section,  background: 'radial-gradient(184.7% 184.7% at 50% 50%, #8338ED 0%, #1E0044 100%)', position: "relative", overflow: "hidden" }}
			 onMouseMove={handleMouseMove}>

			{isLargeScreen && <><Box sx={{height: "220px"}}>
			</Box>
				<Box className={['stellar-man-hover'].join(' ')}
					 style={{
						 position: 'absolute',
						 left: position.x - 170,
						 marginTop: "-70px",
						 transition: 'left 1s ease-out'
					 }}>
					<StellarMan
						hideRightHand={true}
						hideLeftHand={true}
						rightEyeClassName={'rotate-eye-section-3'}
						isSmall={true}
					/>
				</Box></>
			}
			<Grid
				container
				spacing={3}
				justifyContent={{xs: 'center', md: 'space-between'}}
				sx={{textAlign: 'center', px: {sm:0, lg:6}, py: 0,}}
			>
				<Grid item md={2} xs={12}>
					<Typography sx={stellarStyles.outcomeText}>
						Become more <Typography component="span" sx={stellarStyles.outcomeHighlightText}>self-aware</Typography> and sharpen your product skills
					</Typography>
				</Grid>
				<Grid item md={2} xs={12}>
					<Typography sx={stellarStyles.outcomeText}>
						Solve product challenges faster and build <Typography component="span" sx={stellarStyles.outcomeHighlightText}>impactful products</Typography>
					</Typography>
				</Grid>
				<Grid item md={2} xs={12}>
					<Typography sx={stellarStyles.outcomeText}>
						<Typography component="span" sx={stellarStyles.outcomeHighlightText}>Get inspired</Typography> by seeing how other companies solve similar challenges
					</Typography>
				</Grid>
				<Grid item md={2} xs={12}>
					<Typography sx={stellarStyles.outcomeText}>
						Become a <Typography component="span" sx={stellarStyles.outcomeHighlightText}>better</Typography> team builder, communicator, and facilitator
					</Typography>
				</Grid>
				<Grid item md={2} xs={12}>
					<Typography sx={stellarStyles.outcomeText}>
						Grow your <Typography component="span" sx={stellarStyles.outcomeHighlightText}>inner circle</Typography> with people who just get you
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}

function SectionSeven() {
	return (
		<Box
			sx={{
				...stellarStyles.section,
				backgroundImage: "url('/images/solution-bg-7.png')",
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
			id={'section-7'}
		>
			<Grid container>
				<Grid item xs={12} md={4.6} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box sx={{ my: 'auto' }}>
						<Typography sx={{ ...stellarStyles.h2, fontWeight: 700 }}>
							<KoThinkerText text={'KoThinker'} fontSize={headingFontSize} /> structures{' '}
							<StellarText text={'Stellar'} fontSize={headingFontSize} />
							's growth
						</Typography>
						<Typography sx={{ ...stellarStyles.bodySmallPink, pt: '1rem' }}>
							The group meets twice a month. Each time a specific topic is chosen based on the group's
							needs. This helps Stellar to <strong style={{ color: '#FFF' }}>maintain her focus.</strong>
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={7.4}
					sx={{ pl: { xs: 0, md: '2rem' }, pt: { xs: '2.5rem', md: '12rem' }, pb: { xs: 0, md: '18rem' } }}
					position={'relative'}
				>
					<AngleStellarMan />
					<WhyStellarMan />
					<Grid
						container
						gap={'1.125rem'}
						justifyContent={{ xs: 'center', md: 'flex-start' }}
						sx={{ position: 'relative', zIndex: 2 }}
					>
						<Grid item sx={stellarStyles.purpleBox}>
							<Typography sx={stellarStyles.purpleBoxText}>
								Sharing <strong>current challenges</strong> and thereby receiving{' '}
								<strong>situational feedback</strong>
							</Typography>
						</Grid>
						<Grid item sx={stellarStyles.purpleBox}>
							<Typography sx={stellarStyles.purpleBoxText}>
								Value of <strong>hearing other perspectives</strong> and{' '}
								<strong>personal stories</strong> by fellow experts
							</Typography>
						</Grid>
						<Grid item sx={stellarStyles.purpleBox}>
							<Typography sx={stellarStyles.purpleBoxText}>
								<strong>Experimenting with the learnings</strong> on the daily job and sharing the
								results with others
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

function SectionEight() {
	return (
		<Box sx={{ ...stellarStyles.section, background: '#E9DEFF', position: 'relative' }}>
			<Grid container direction={{ xs: 'column-reverse', md: 'row' }}>
				<Grid item xs={12} md={5}>
					<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: '1.5rem' }}>
						<Typography
							sx={{
								...stellarStyles.koThinker,
								fontSize: { xs: '2.125rem', md: '3.125rem' },
								lineHeight: '100% !important',
								letterSpacing: '-0.0625rem',
							}}
						>
							An automated agenda keeps time during the sessions
						</Typography>
						<Typography sx={stellarStyles.bodyDark}>
							This helps to make sure everyone in the group is heard and all the necessary steps are
							followed.
						</Typography>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{
						width: '100%',
						top: '8rem',
						height: 'fit-content',
						pt: { xs: '1rem', md: 0 },
						marginLeft: 'auto',
						minHeight: { xs: 'unset', md: '28rem' },
					}}
				>
					<AgendaItems styles={{ top: 15, right: '2rem' }} />

					<Box sx={{ position: 'relative', right: { md: '5rem', xs: 0 } }}>
						<img src={'/images/solution-bg-5.png'} alt={'conference'} style={{ width: '100%' }} />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

function SectionNine() {
	return (
		<Box sx={{ position: 'relative' }}>
			<ForgottenStellarMan1 />
			<ForgottenStellarMan2 />
			<Box
				sx={{
					zIndex: 2,
					position: 'relative',
					...stellarStyles.section,
					height: { xs: 'auto', md: '30rem' },
					display: { xs: 'block', md: 'flex' },
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: { xs: 'auto', md: 'center' },
				}}
			>
				<Typography sx={{ ...stellarStyles.h2, pb: { xs: '1.125rem', md: '2.5rem' } }}>
					<StellarText text={'Stellar'} fontSize={{ xs: '2.125rem', md: '3.125rem' }} /> had already forgotten{' '}
					<br /> how fun learning can be
				</Typography>
				<Typography sx={stellarStyles.bodySmallPink}>
					The feeling of laughing and sharing failures with your co-thinkers builds strong connections.
				</Typography>
			</Box>
			<Box
				sx={{
					...stellarStyles.section,
					height: { xs: 'auto', md: '28rem' },
				}}
			>
				<Box
					sx={{
						maxWidth: { xs: 'auto', md: '31rem', display: 'flex', flexDirection: 'column', gap: '1.5rem' },
					}}
				>
					<Typography
						sx={{
							color: '#fff',
							fontWeight: 700,
							fontSize: headingFontSize,
							lineHeight: '100% !important',
						}}
					>
						Dream <StellarText text={'big'} fontSize={headingFontSize} />. Start{' '}
						<StellarText text={'small'} fontSize={headingFontSize} />
					</Typography>
					<Typography sx={stellarStyles.bodySmallPink}>
						At the end of each session <StellarText text={'Stellar'} fontSize={'1.125rem'} /> designs a
						learning experiment - something to try out in order to achieve a greater impact. The step can be
						however small, but it must be practical. <StellarText text={'Stellar'} fontSize={'1.125rem'} />{' '}
						then promises the group to follow through with it.
					</Typography>
					<Typography sx={stellarStyles.bodySmallPink} id={'section-9-bottom'}>
						A few days before the next session <StellarText text={'Stellar'} fontSize={'1.125rem'} />{' '}
						receives a reminder email filling her with excitement of the upcoming reunion with her
						co-thinkers. As she comes prepared with each completed learning experiment to share, the group
						gathers, listens to personal insights, and celebrates their successes.
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

function SectionTen() {
	return (
		<Box
			sx={{
				...stellarStyles.section,
				background: 'linear-gradient(180deg, #9B57FA -28%, rgba(30, 0, 68, 0.00) 73%);',
				textAlign: 'center',
				pt: { md: '11.5rem', xs: '7.5rem' },
				pb: { md: '9.3rem', xs: '3rem' },
				overflow: 'hidden',
				position: 'relative',
			}}
		>
			<Box
				sx={{
					opacity: 0.8,
					width: '1px',
					height: '1px',
					boxShadow: '0 0 500px 170px #9B57FA',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			/>
			<img src={'/images/pyramid-V1.png'} alt={'conference'} className={'stellar-triangle-bg'} />

			<Box sx={{ zIndex: 1, position: 'relative' }}>
				<Typography sx={{ ...stellarStyles.h2, pb: '2.5rem' }}>
					This is true <strong>self-actualization.</strong>
				</Typography>
				<Typography
					sx={{
						...stellarStyles.bodySmallPink,
						maxWidth: '50rem',
						textAlign: 'center',
						mx: 'auto',
						lineHeight: '140%',
					}}
				>
					Structure and meaningful co-thinkers. People who will always be there by your side and cheer for
					your success. True mentors who make it easy for you to create impact and feel endless work joy
				</Typography>
			</Box>
		</Box>
	);
}

function SectionEleven() {
	return (
		<Box
			sx={{
				...stellarStyles.section,
				background: '#1E0044',
				textAlign: { xs: 'auto', md: 'center' },
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '2.5rem',
					pb: { xs: '1rem', md: '3.75rem' },
				}}
			>
				<Typography sx={{ ...stellarStyles.h2, pt: { xs: 0, md: '4.38rem' }, pb: 0 }}>
					Intelligence is the ability to apply knowledge that <br /> leads to self-fulfillment, which we call
					work joy!
				</Typography>
				<Typography sx={{...stellarStyles.bodySmallPink, mt: "-1rem", alignSelf: "end", mr: "10%", fontStyle: "italic"}}>- Aive Uus</Typography>
				<Stack sx={{alignItems: "start", width: "100%", textAlign: "left !important"}}>
					<GroupSelection solutionStoryLayout={true}/>
				</Stack>
				{/*<LetsTalk />*/}
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Typography sx={stellarStyles.footerText}>©2024 – All Rights Reserved</Typography>
				<Typography sx={stellarStyles.footerText}>kothinker.com</Typography>
			</Box>
		</Box>
	);
}

export function StellarText({
	text,
	fontSize,
	isWhite,
}: {
	text: string;
	fontSize?: string | { md: string; xs: string };
	isWhite?: boolean;
}) {
	return (
		<Typography
			component={'span'}
			sx={{
				...stellarStyles.stellar,
				fontSize: fontSize ?? '1.75rem',
				color: isWhite ? '#FFF' : '#8338ED',
			}}
		>
			{text}
		</Typography>
	);
}

function KoThinkerText({
	text,
	isWhite,
	fontSize,
}: {
	text: string;
	isWhite?: boolean;
	fontSize?: string | { md: string; xs: string };
}) {
	return (
		<Typography
			component={'span'}
			sx={{ ...stellarStyles.koThinker, fontSize: fontSize ?? '1.75rem', color: isWhite ? '#FFF' : '#8338ED' }}
		>
			{text}
		</Typography>
	);
}

export default SolutionStory;
