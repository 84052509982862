import React from 'react';

function ArrowImage({width, color = "#FFFFFF", marginLeft = "1rem"}: {
    width: number,
    color?: string,
    marginLeft?: string
}) {
    const height = width / 1.6667
    return (
        <svg style={{marginLeft: marginLeft}} width={width + "rem"} height={height + "rem"} viewBox="0 0 20 12" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.8387 6.8471C19.3072 6.37857 19.3072 5.61768 18.8387 5.14915L14.0409 0.351398C13.5724 -0.117133 12.8115 -0.117133 12.343 0.351398C11.8744 0.819928 11.8744 1.58082 12.343 2.04935L15.0942 4.80056H1.19944C0.535999 4.80056 0 5.33656 0 6C0 6.66344 0.535999 7.19944 1.19944 7.19944H15.0942L12.343 9.95065C11.8744 10.4192 11.8744 11.1801 12.343 11.6486C12.8115 12.1171 13.5724 12.1171 14.0409 11.6486L18.8387 6.85085V6.8471Z"
                fill={color}/>
        </svg>


    );
}

export default ArrowImage;
