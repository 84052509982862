import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import LinkedInImage from "../../assets/LinkedInImage";
import CtaButton from "../../CtaButton";

function TestimonialBox({picUrl, author, position, text, linkedin}: {
    picUrl: string,
    author: string,
    position: string,
    text: string,
    linkedin: string
}) {
    return (
        <Stack spacing={2} alignItems={"center"}
               sx={{
                   display: "flex",
                   padding: "1.25rem",
                   alignItems: "center",
                   borderRadius: "1.25rem",
                   border: "1px solid #E1E1E1",
                   background: "#FFF",
                   width: "calc(100% - 2.5rem)",
               }}>
            <Grid container spacing={2}>
                <Grid item xs={11} display={"flex"} alignItems={"center"}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Box sx={{
                            borderRadius: "50%",
                            height: {xs: "3.75rem"},
                            width: {xs: "3.75rem"},
                            minHeight: {xs: "3.75rem"},
                            minWidth: {xs: "3.75rem"},
                            backgroundImage: "url(/images/" + picUrl + ")",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}/>
                        <Stack direction={"column"} spacing={2} alignItems={"start"} paddingLeft={"0rem"}
                        >
                            <Typography sx={{
                                color: "#676AA7",
                                fontSize: {xs: "1rem"},
                                lineHeight: {xs: "1.125rem"},
                                fontWeight: 700,
                            }}>{author}</Typography>
                            <Typography sx={{
                                color: "#434343",
                                fontSize: {xs: "1rem"},
                                lineHeight: {xs: "1.125rem"},
                                marginTop: "0.3rem !important",
                            }}>{position}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={1} sx={{display: "flex", alignItems: "start", justifyContent: "end"}}>
                    <Box sx={{cursor: "pointer"}} onClick={() => window.open(linkedin, "_blank")}>
                        <LinkedInImage size={18}/>
                    </Box>
                </Grid>
            </Grid>

            <Stack>
                <Typography fontSize={"1rem"}
                            color={"#434343"}
                            sx={{whiteSpace: "pre-line", lineHeight: "134%"}}
                            dangerouslySetInnerHTML={{__html: text}}
                >

                </Typography>

            </Stack>
        </Stack>
    )
}

function Testimonials() {


    return (
        <>
            <Typography variant={"h1"} sx={{
                textAlign: "center", marginBottom: "1.5rem",
                fontSize: {xs: "1.5rem", md: "3.75rem"},
                lineHeight: {xs: "1.7rem", md: "3.75rem"},
            }}>User experiences</Typography>
            <Grid container sx={{
                maxWidth: "85.44rem",
                width: {xs: "calc(100% - 2rem)", md: "calc(100% - 8rem)"},
                alignItems: "center",
                paddingLeft: {xs: "1rem", md: "4rem"},
                paddingRight: {xs: "1rem", md: "4rem"},
            }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TestimonialBox picUrl={"urmo.webp"}
                                        position={"I’m passionate about product-led growth"}
                                        author={"Urmo Keskel"}
                                        text={
                                            "I’m thrilled to share that I am part of the first KoThinker Product Managers’ Mastermind Group, and I’m truly witnessing tremendous value for my professional growth. 🌱📈\n" +
                                            "\n" +
                                            "<span class='simple-highlight'>This platform offers an exceptional opportunity to engage with fellow product managers, exchange invaluable insights, and draw from each other’s experiences.</span> 🤝💡\n" +
                                            "Next PM co-learning groups are starting soon, if you’re a product manager on the lookout for ways to accelerate your development and connect with like-minded peers, this is an outstanding chance. 🌟\n" +
                                            "\n" +
                                            "Don’t miss out on the chance to propel your career forward while building meaningful connections! 🚀🌐\n" +
                                            "\n" +
                                            "#ProductManagement #ProfessionalGrowth #MastermindGroup #PeerLearning "
                                        }
                                        linkedin={"https://www.linkedin.com/posts/urmo-keskel-7a73031b0_hey-product-managers-if-you-wish-to-activity-7095400371434532864--zds?utm_source=share&utm_medium=member_desktop"}
                        />

                        <Box sx={{height: {xs: "1.5rem"}}}/>

                        <TestimonialBox picUrl={"meelik.webp"}
                                        position={"Product strategist, mentor and a start-up operator "}
                                        author={"Meelik Gornoi"}
                                        text={
                                            "I suggest every PM have a co-thinking group. <span class='simple-highlight'>You can achieve so much more by sharing and learning from other perspectives.</span> \n\n" +
                                            "#empoweringpeople #productexcellence"
                                        }
                                        linkedin={"https://www.linkedin.com/posts/meelik_hey-product-managers-if-you-wish-to-activity-7095491585911455745-eNjd?utm_source=share&utm_medium=member_desktop"}
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TestimonialBox picUrl={"keirin.webp"}
                                        position={"CPO | Passionate about building and leading software products"}
                                        author={"Keirin Rebane"}
                                        text={
                                            "Here’s your shot at finding folks who get you. <span class='simple-highlight'>Being in a group that’s got your back, " +
                                            "where people in my field are tackling the exact same issues I deal with – well, " +
                                            "that’s been a game-changer in my day-to-day work life.</span>   🙌\n\n" +
                                            "I’ve gotta say, I wholeheartedly vouch for Kothinker.\n\n" +
                                            "’Cause let’s face it, we all deal with different work challenges every day, and having multiple brains in the game? Always a win. 🚀"
                                        }
                                        linkedin={"https://www.linkedin.com/posts/keirin-rebane_growthmindset-joyoflearning-togetherwecan-activity-7095689019782537216-YQbv?utm_source=share&utm_medium=member_desktop"}
                        />
                        <Box sx={{height: {xs: "1.5rem"}}}/>
                        <TestimonialBox picUrl={"mikk.webp"}
                                        position={"Senior Product Manager"}
                                        author={"Mikk Vachtmeister"}
                                        text={
                                            "Delighted to share my first-ever interview! I believe it turned out quite well 😀 I highly recommend joining a KoThinker mastermind group for any aspiring PMs out there. <span class='simple-highlight'>It's one thing to learn about product management from books, but it's far more enriching to hear firsthand from peers who are applying these concepts in real scenarios and to receive feedback on your own strategies.</span>  Joining KoThinker could be a game-changer for your career."
                                        }
                                        linkedin={"https://www.linkedin.com/feed/update/urn:li:activity:7186708948698206209/?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A7186708948698206209%2C7186722253227638784%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287186722253227638784%2Curn%3Ali%3Aactivity%3A7186708948698206209%29"}
                        />


                    </Grid>

                </Grid>
            </Grid>
            <Box sx={{height: {xs: "2rem", md: "3rem"}}}/>

            <CtaButton/>

        </>
    )
        ;
}

export default Testimonials;