import React from 'react';
import {Box, Stack, Typography, Link} from "@mui/material";
import LinkedInImage from "../assets/LinkedInImage";


function TestemonialSlide({
                              name,
                              title,
                              testemonial,
                              picUrl,
                              linkedIn,
                          }: { name: string, title: string, testemonial: string, picUrl: string, linkedIn?: string }) {


    return (
        <Stack sx={{
            position: "relative", backgroundColor: "#F6F6F9",
            borderRadius: {md: "13.75rem", xs: "2.5rem"},
            padding: {md: 0, xs: "2rem"},
            height: {md: "100%", xs: "calc(100% - 4rem)"},
        }}>

            <Stack sx={{height: "100%"}}>

                <Stack direction={{md: "row", xs: "column"}}
                       sx={{
                           alignItems: "center",
                           position: "relative",
                           minHeight: {lg: "21rem", md: "18rem", xs: "18rem"},
                           height: "100%"
                       }}>
                    <Box sx={{
                        borderRadius: "50%",
                        marginLeft: {lg: 0, md: "1.5rem", xs: 0},
                        marginBottom: {lg: 0, md: 0, xs: "0.5rem"},
                        position: {md: "absolute", xs: "relative"},
                        top: {lg: "calc(50% - 10.5rem)", md: "auto", xs: "auto"},
                        left: {lg: 0, xs: "auto"},
                        height: {lg: "21rem", md: "16rem", xs: "9rem"},
                        width: {lg: "21rem", md: "16rem", xs: "9rem"},
                        minHeight: {lg: "21rem", md: "16rem", xs: "9rem"},
                        minWidth: {lg: "21rem", md: "16rem", xs: "9rem"},
                        backgroundImage: "url(/images/" + picUrl + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}/>
                    <Stack sx={{padding: {lg: "1rem 8rem 1rem 23rem", md: "0.5rem 6rem 0.5rem 19rem", xs: 0}}}>
                        <Typography sx={{
                            fontSize: {xs: "0.875rem !important", lg: "1rem !important", md: "0.875rem !important"},
                        }} dangerouslySetInnerHTML={{__html: testemonial}}/>
                        <Stack sx={{height: "100%", marginTop: "1rem", alignItems: {md: "start", xs: "center"}}}>
                            <Typography sx={{
                                typography: {xs: 'h2', md: 'h2'},
                                fontSize: {xs: "1.5rem", md: "1.875rem"},
                                lineHeight: {xs: "1.6rem", md: "1.9rem"},

                            }}>{name}</Typography>
                            <Stack direction={"row"}>
                                <Typography sx={{
                                    fontSize: {xs: "0.875rem", md: "1rem"},
                                    lineHeight: {xs: "1.125rem", md: "1.4rem"},
                                    marginRight: "0.5rem",
                                    color: "#36374A"
                                }}>{title}</Typography>
                                {linkedIn && <Link target={"_blank"} href={linkedIn}>
                                    <LinkedInImage size={16}/>
                                </Link>}
                            </Stack>

                        </Stack>
                    </Stack>

                </Stack>
            </Stack>
        </Stack>
    )
        ;
}

export default TestemonialSlide;
