import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import Team from './Team';
import Blog from '../blog/Blog';
import BlogEntry from '../blog/BlogEntry';
import { userAtom } from '../../store/userStore';
import { themeAtom } from '../../store/themeStore';
import { useAtom, useAtomValue } from 'jotai';
import KoThinker from '../kothinker/KoThinker';
import Contact from '../sections/Contact';
import KoThinkerFaq from '../sections/kothinker/KoThinkerFaq';
import SolutionStory from '../solutionStory/SolutionStory';
import VirtualShares from '../sections/VirtualShares';

function AppRoutes() {
	const location = useLocation();
	const Login = lazy(() => import('./Login'));
	const Admin = lazy(() => import('../admin/Admin'));
	const AdminBlogEntry = lazy(() => import('../admin/AdminBlogEntry'));

	useEffect(() => {
		let title = 'Home | KoThinker';
		if (location.pathname === '/blog') {
			title = 'Blog | KoThinker';
		}
		if (location.pathname === '/team') {
			title = 'Team | KoThinker';
		}
		if (location.pathname === '/contact') {
			title = 'Contact | KoThinker';
		}
		if (location.pathname === '/faq') {
			title = 'FAQ | KoThinker';
		}
		if (location.pathname === '/solution') {
			title = 'Solution | KoThinker';
		}
		if (location.pathname === '/virtualshares') {
			title = 'Virtual shares | KoThinker';
		}
		document.title = title;
	});

	const [, setTheme] = useAtom(themeAtom);

	useEffect(() => {
		if (location.pathname === '/solution') {
			setTheme({ mode: 'dark' });
		} else {
			setTheme({ mode: 'light' });
		}
	}, [location]);

	const user = useAtomValue(userAtom);

	const routes = [];

	if (user) {
		console.log('user', user);
		routes.push(
			<Route path="/admin" element={<Admin />} />,
			<Route path="/admin/blog/:id" element={<AdminBlogEntry />} />,
		);
	}

	routes.push(
		<Route path="/login" element={<Login />} />,
		<Route path="/" element={<KoThinker />} />,
		<Route path="/blog" element={<Blog />} />,
		<Route path="/team" element={<Team />} />,
		<Route path="/contact" element={<Contact />} />,
		<Route path="/faq" element={<KoThinkerFaq />} />,
		<Route path="/virtualshares" element={<VirtualShares />} />,
		<Route path="/solution" element={<SolutionStory />} />,
		<Route path="/blog/:id" element={<BlogEntry />} />,
	);

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Routes>
				{routes.map((route, i) => (
					<React.Fragment key={i}>{route}</React.Fragment>
				))}
			</Routes>
		</Suspense>
	);
}

export default AppRoutes;
