import React from "react";

const PICS: Map<string, string> = new Map([['anti@kofounder.ee', 'anti.webp']]);

export function formatDate(date: Date): string {
    return date.toLocaleString('default', {
        month: 'long',
        day: "numeric",
        year: 'numeric'
    })
}

export function getPicUrl(email: string): string {
    const url = PICS.get(email)
    return url ? url : "";
}

export const isValidEmail = (email: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );