import React from 'react';

function CloseAccordionImage() {
    return (

        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="14" fill="#676AA7"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.6569 9.75732L18.2426 8.3431L14 12.5857L9.75736 8.3431L8.34315 9.75732L12.5858 14L8.34315 18.2426L9.75736 19.6568L14 15.4142L18.2426 19.6568L19.6569 18.2426L15.4142 14L19.6569 9.75732Z"
                  fill="white"/>
        </svg>


    );
}

export default CloseAccordionImage;
