import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StellarMan from './StellarMan';

function VideoStellarMan() {
	const [isVisible, setIsVisible] = useState(false);

	// If block with big percentage comes into view, focus stellar eyes on that element.
	function handleStellarFadeIn() {
		const blockElement = document.getElementById('section-five');
		if (blockElement) {
			const block = blockElement.getBoundingClientRect();
			// If section's bottom part is in view
			if (block.bottom <= window.innerHeight) {
				setIsVisible(true);
			}
		}
	}

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('scroll', handleStellarFadeIn);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('scroll', handleStellarFadeIn);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// function rotateEyes(deg: number) {
	// 	setLeftEyeStyles({
	// 		transform: `rotate(${deg}deg)`,
	// 	});
	// 	setRightEyeStyles({
	// 		transform: `rotate(${deg}deg)`,
	// 	});
	// }

	// useEffect(() => {
	// 	if (isVisible) {
	// 		rotateEyes(245);
	// 		setTimeout(() => {
	// 			rotateEyes(310);
	// 		}, 2000);
	// 		setTimeout(() => {
	// 			rotateEyes(245);
	// 		}, 5000);
	// 	}
	// }, [isVisible]);

	return (
		<Box
			sx={{
				zIndex: 1,
				display: { md: 'block', xs: 'none' },
				position: 'absolute',
				right: '21px',
				marginLeft: '6rem',
				bottom: '332px',
				width: '200px',
				height: '200px',
			}}
			id={'stellar-man'}
			className={['stellar-container', isVisible ? 'stellar-fade-in-right' : 'stellar-hidden'].join(' ')}
		>
			<Box className={['stellar-man-hover-fast'].join(' ')}>
				<StellarMan
					hideRightHand={true}
					rightEyeClassName={isVisible ? 'rotate-eye-section-5' : ''}
					leftEyeClassName={isVisible ? 'rotate-eye-section-5' : ''}
					leftHandClassName={'stellar-left-hand-wave-small'}
					isSmall={true}
				/>
			</Box>
		</Box>
	);
}

export default VideoStellarMan;
