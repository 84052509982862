import React from 'react';

function LargeArrowImage({width, reverse = false}: { width: number, reverse?: boolean }) {
    const height = width / 1.348

    return (
        <svg style={{marginTop: "0.5rem", transform: reverse ? "rotateY(180deg)" : "auto"}} width={width + "rem"}
             height={height + "rem"}
             viewBox="0 0 62 46" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 20C1.34315 20 1.44847e-07 21.3431 0 23C-1.44847e-07 24.6569 1.34315 26 3 26L3 20ZM61.1213 25.1213C62.2929 23.9498 62.2929 22.0503 61.1213 20.8787L42.0294 1.7868C40.8579 0.615226 38.9584 0.615226 37.7868 1.7868C36.6152 2.95837 36.6152 4.85787 37.7868 6.02944L54.7574 23L37.7868 39.9706C36.6152 41.1421 36.6152 43.0416 37.7868 44.2132C38.9584 45.3848 40.8579 45.3848 42.0294 44.2132L61.1213 25.1213ZM3 26L59 26L59 20L3 20L3 26Z"
                fill="white"/>
        </svg>


    );
}

export default LargeArrowImage;
