import React from 'react';
import {Grid, Stack, Typography} from "@mui/material";
import TeamProfile from "../TeamProfile";
import {Swiper, SwiperSlide} from "swiper/react";


function TeamSection() {

    return (
        <>
            <Typography variant={"h1"} sx={{
                textAlign: "center", marginBottom: "1.5rem",
                fontSize: {xs: "1.5rem", md: "3.75rem"},
                lineHeight: {xs: "1.7rem", md: "3.75rem"},
            }}>Meet our team</Typography>

            <Grid container sx={{
                display: {md: "flex", xs: "none"},
                maxWidth: "65.44rem",
                width: "100%",
                paddingLeft: {xs: "1rem", md: "4rem"},
                paddingRight: {xs: "1rem", md: "4rem"},
                alignItems: "center"
            }}
            >
                <Grid item xs={12} md={6} lg={6} sx={{
                    padding: "0rem 0.75rem 0.75rem 0rem"
                }}>
                    <TeamProfile name={"Aive Uus"} title={"CEO"}
                                 desc={"Has grown organizations from 10 to 100+ people. Globally experienced startup mentor. Has built leadership and product communities"}
                                 picUrl={"aive.webp"} flipPicUrl={"aive_flip.webp"}/>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{
                    padding: {xs: "0.75rem 0.75rem 0.75rem 0.75rem", md: "0rem 0.75rem 0.75rem 0.75rem"}
                }}>
                    <TeamProfile name={"Anti Orgla"} title={"Development"}
                                 desc={"Has developed and led complex software systems that have scaled over millions of transactions per day"}
                                 picUrl={"anti.webp"} flipPicUrl={"anti_flip.webp"}/>
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{

                    padding: {
                        xs: "0.75rem 0.75rem 0.75rem 0.75rem",
                        md: "0.75rem 0.75rem 0rem 0rem",
                        lg: "0.75rem 0.75rem 0rem 0rem"
                    }
                }}>
                    <TeamProfile name={"Loore Aaslav-Kaasik"} title={"Behaviour research"}
                                 desc={"Has spent two decades expanding her academic knowledge in various disciplines. Currently pursuing a PhD in health behaviour and wellbeing"}
                                 picUrl={"loore.webp"} flipPicUrl={"loore_flip.webp"}/>
                </Grid>

                <Grid item xs={12} md={6} lg={6} sx={{
                    padding: {
                        xs: "0.75rem 0.75rem 0.75rem 0.75rem",
                        md: "0.75rem 0.75rem 0rem 0.75rem",
                        lg: "0.75rem 0.75rem 0rem 0.75rem"
                    }
                }}>
                    <TeamProfile name={"Riho Jaska"} title={"Product & Data"}
                                 desc={"Has built 10+ product teams in private and public sector; excellent in research and solution finding"}
                                 picUrl={"riho.webp"} flipPicUrl={"riho_flip.webp"}/>
                </Grid>

            </Grid>

            <Stack sx={{
                display: {md: "none", xs: "block"},
                maxWidth: "85.44rem",
                width: "100%",
                alignItems: "center"
            }}
            >
                <Swiper
                    className="testimonial"
                    speed={1000}
                    spaceBetween={16}
                    slidesPerView={1.2}
                    loop={true}
                    watchOverflow={false}
                    centeredSlides={true}
                    updateOnWindowResize={true}
                    grabCursor={true}
                    style={{
                        width: "100%",
                    }}

                >
                    <SwiperSlide>
                        <TeamProfile name={"AIVE UUS"} title={"CEO"}
                                     desc={"Has grown organizations from 10 to 100+ people. Strategic leading expert. Globally experienced startup mentor"}
                                     picUrl={"aive.webp"} flipPicUrl={"aive_flip.webp"}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <TeamProfile name={"ANTI ORGLA"} title={"CTO"}
                                     desc={"Has developed and led complex software systems that have scaled over millions of transactions per day"}
                                     picUrl={"anti.webp"} flipPicUrl={"anti_flip.webp"}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <TeamProfile name={"Loore Aaslav-Kaasik"} title={"User behaviour researcher"}
                                     desc={"Has spent two decades expanding her academic knowledge in various disciplines. Currently pursuing a PhD in health behaviour and wellbeing"}
                                     picUrl={"loore.webp"} flipPicUrl={"loore_flip.webp"}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <TeamProfile name={"HELEN KALJUSAAR"} title={"Marketing Lead"}
                                     desc={"Has created nationwide marketing campaigns for leading brands. Focusing on strategic digital marketing"}
                                     picUrl={"helen.webp"} flipPicUrl={"helen_flip.webp"}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <TeamProfile name={"RIHO JASKA"} title={"Product Lead"}
                                     desc={"Has built 10+ product teams in private and public sector; excellent in research and solution finding"}
                                     picUrl={"riho.webp"} flipPicUrl={"riho_flip.webp"}/>
                    </SwiperSlide>
                    <SwiperSlide>
                        <TeamProfile name={"JOEL EDENBERG"} title={"Lead Developer"}
                                     desc={"Has found technical solutions to complex problems. Built several startups with hundreds of integrations"}
                                     picUrl={"joel.webp"} flipPicUrl={"joel_flip.webp"}/>
                    </SwiperSlide>
                </Swiper>
            </Stack>
        </>
    )
        ;
}

export default TeamSection;
