import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation, Pagination} from "swiper";
import {Box, Grid, Stack, Typography} from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme/theme";
import useKoThinkerApi from "../../../api/useKoThinkerApi";
import ArrowImage from "../../../assets/ArrowImage";
import {IUpcomingSession} from "./model/IUpcomingSession";
import SessionSlide from "./SessionSlide";

const styles = {
    slide: {
        height: "auto",
        width: "auto",
    },
};


function UpcomingEvents() {

    const [events, setEvents] = useState<IUpcomingSession[]>([])

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const swiperRef = useRef<SwiperCore>();

    const [dynamicOffset, setDynamicOffset] = useState(
        md ? (window.innerWidth / 2 - 619.52) : 14
    );

    const {getUpcomingSessions} = useKoThinkerApi()

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setDynamicOffset(md ? (window.innerWidth / 2 - 619.52) : 14);
    };

    useEffect(() => {
        getUpcomingSessions()
            .then((data) => {
                setEvents([...data]);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const swiperButtonStyle: CSSProperties = {
        position: 'relative',
        display: 'inline-flex',
        width: '60px',
        height: '60px',
        marginRight: '16px',
        marginLeft: '16px',
        marginTop: 0,
        borderRadius: '100%',
        backgroundColor: '#947EC7',
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    };
    return (

        <>
            <Stack sx={{
                maxWidth: "77.44rem",
                width: {xs: "calc(100% - 2rem)", md: "calc(100% - 8rem)"},
                paddingLeft: {xs: "1rem", md: "4rem"},
                paddingRight: {xs: "1rem", md: "4rem"},
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Grid container>
                    <Grid item xs={0} lg={3}></Grid>
                    <Grid item xs={12} lg={6} px={{xs: 0, md: 3}}>
                        <Typography
                            variant={"h1"}
                            id="eventSection"
                            sx={{
                                textAlign: "center",
                                alignSelf: "center",
                                marginBottom: "1.5rem",
                                marginLeft: 0,
                                fontSize: {xs: "1.5rem", md: "3.75rem"},
                                color: "#947EC7",
                                lineHeight: {xs: "1.7rem", md: "3.75rem"},
                            }}>Discussion events </Typography>
                    </Grid>
                    {md && <Grid item xs={12} lg={3} mb={3} alignSelf="start" justifyContent={{xs: "center", md: "end"}}
                                 display="flex">
                        {md && <div
                            className={'swiper-pagination'}></div>}
                        <div id={"swiper-button-prev-upcoming-events"}
                             className="swiper-button-prev" style={{...swiperButtonStyle, transform: 'rotate(180deg)'}}>
                            <ArrowImage width={1.4} marginLeft={"0"}/></div>
                        <div id={"swiper-button-next-upcoming-events"}
                             className="swiper-button-next" style={swiperButtonStyle}><ArrowImage width={1.4}
                                                                                                  marginLeft={"0"}/>
                        </div>
                    </Grid>}
                </Grid>
            </Stack>


            <Grid key={"swiper-container2"} container sx={{
                width: "100%",
                position: "relative",
                paddingRight: {xs: "0rem", md: "0rem"},
                paddingLeft: {xs: "1rem", md: "0rem"},
                alignItems: "center",
            }}>

                <Swiper modules={[Pagination, Navigation]}
                        className="testimonial"
                        slidesPerView={"auto"}
                        id={"upcoming-events"}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        slidesOffsetBefore={dynamicOffset}
                        simulateTouch={true}
                        pagination={{
                            clickable: true,
                            horizontalClass: "testimonial-pagination",
                            el: '.swiper-pagination',
                            type: 'fraction',
                        }}
                        navigation={{
                            nextEl: '#swiper-button-next-upcoming-events',
                            prevEl: '#swiper-button-prev-upcoming-events',
                        }}
                        loop={false}
                        centeredSlides={false}
                        spaceBetween={8}
                        grabCursor={true}
                        style={{
                            width: "100%",
                            paddingBottom: "2rem",
                        }}
                >
                    <SwiperSlide style={styles.slide} key={"waitinglist"}>

                    </SwiperSlide>
                    {events.filter(
                        (event) => !event.endTime).map(event =>
                        <SwiperSlide style={styles.slide} key={event.id}>
                            <SessionSlide sessionInfo={event}></SessionSlide>
                        </SwiperSlide>
                    )}
                    {events.filter(
                        (event) => event.endTime).map(event =>
                        <SwiperSlide style={styles.slide} key={event.id}>
                            <SessionSlide sessionInfo={event}></SessionSlide>
                        </SwiperSlide>
                    )}


                </Swiper>
                {!md && <Grid item xs={12} mb={3} alignSelf="start" justifyContent={{xs: "center", md: "end"}}
                              display="flex" sx={{marginTop: "-30px"}}>
                    {md && <Box
                        className={'swiper-pagination'}></Box>}
                    <Box className="swiper-button-prev" id={"wiper-button-prev-upcoming-events"}
                         style={{...swiperButtonStyle, transform: 'rotate(180deg)'}}>
                        <ArrowImage width={1.4} marginLeft={"0"}/></Box>
                    <Box className="swiper-button-next" id={"swiper-button-next-upcoming-events"}
                         style={swiperButtonStyle}><ArrowImage width={1.4}
                                                               marginLeft={"0"}/>
                    </Box>*
                </Grid>}
            </Grid>
        </>
    )
        ;
}

export default UpcomingEvents;
