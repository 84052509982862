import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination} from "swiper";
import {Typography} from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import TestemonialSlide from "../TestemonialSlide";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../theme/theme";

const styles = {
    slide: {
        height: "auto",
        maxWidth: "77.44rem",
    },
};

function TeamTestimonials() {

    const md = useMediaQuery(theme.breakpoints.up('md'));

    const swiperRef = useRef<SwiperCore>();


    function slide() {
        let clicked = swiperRef.current?.clickedIndex
        if (clicked) {
            swiperRef.current?.slideTo(clicked)
        }

    }

    function breakPointChange(s: SwiperCore) {
        if (s.pagination.el) {
            if (s.currentBreakpoint === 'max') {
                s.pagination.el.classList.remove("testimonial-pagination")
                s.pagination.el.classList.add("testimonial-pagination-hidden")
            } else {
                s.pagination.el.classList.add("testimonial-pagination")
                s.pagination.el.classList.remove("testimonial-pagination-hidden")
            }
        }

    }

    return (
        <>
            <Typography variant={"h1"} sx={{
                textAlign: "center", marginBottom: "1.5rem",
                fontSize: {xs: "1.5rem", md: "3.75rem"},
                lineHeight: {xs: "1.7rem", md: "3.75rem"},
            }}>Team showcase</Typography>

            <Swiper modules={[Pagination]}
                    className="testimonial"
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    onInit={(s) => {
                        breakPointChange(s)
                    }}
                    pagination={{
                        clickable: true,
                        horizontalClass: "testimonial-pagination"
                    }}
                    spaceBetween={16}
                    slidesPerView={1.2}
                    loop={true}
                    loopedSlides={3}
                    initialSlide={0}
                    centeredSlides={true}
                    grabCursor={true}
                    style={{
                        width: "100%",
                        paddingBottom: md ? "3rem" : 0,
                    }}
                    onClick={() => slide()}
                    onBreakpoint={(s) => breakPointChange(s)}

                    breakpoints={{
                        900: {
                            slidesPerView: 1.1,
                        },
                        1440: {
                            slidesPerView: "auto",
                            spaceBetween: 24
                        },
                        2000: {
                            slidesPerView: "auto",
                            spaceBetween: 24,
                        },

                    }}
            >
                <SwiperSlide style={styles.slide}>
                    <TestemonialSlide name={"Mart Maasik"} title={"Head of Partnerships @ kood/Jõhvi"}
                                      linkedIn={"https://www.linkedin.com/in/maasik/"}
                                      testemonial={"\"Our team partnered with KoFounder on a high-risk project to apply kood/Jõhvi learning framework to corporate customer segments. KoFounder demonstrated <strong>quick progress, delivering value through transparency, predictability, and well-aligned goals.</strong> We feel optimistic and empowered as we defined the real problem and proved its solution with the first paying customer’s high satisfaction.\""}
                                      picUrl={"mart.webp"}/>
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <TestemonialSlide name={"Maris Moorus"} title={"Project Manager @ Estonian Business School"}
                                      linkedIn={"https://www.linkedin.com/in/maris-m-456a56228/"}
                                      testemonial={"\"While creating a startup, we were looking for a <strong>professional partner</strong> with whom we could develop the business and who could help us with the <strong>know-how in startup and IT world</strong>. I'm glad we found a common direction, ideas, and values with KoFounder. We've been working together for over a year and are delighted. The team consists of specialists at their field, and the cooperation has been <strong>smooth and high-quality</strong>.\""}
                                      picUrl={"maris.webp"}/>
                </SwiperSlide>
                <SwiperSlide style={styles.slide}>
                    <TestemonialSlide name={"Britta Burket"} title={"Founder @ Rikardia"}
                                      linkedIn={"https://www.linkedin.com/in/britta-burket-0549a78/"}
                                      testemonial={"\"We needed a <strong>partner to guide us through creating a health platform</strong>. When starting out, thousands of ideas run through your brain, but not all are reasonable to focus on in the beginning. KoFounder explained the start-up terms in human language to the rookies who we were. <strong>When we saw our almost-finished MVP, we knew we had chosen the right partner</strong>. We admire Aive’s empathy and wisdom and how the same traits have spread over to the team.\""}
                                      picUrl={"britta.webp"}/>
                </SwiperSlide>
            </Swiper>
        </>
    )
        ;
}

export default TeamTestimonials;
